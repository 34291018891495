import React, { useState, useEffect } from "react";
import Background from "../components/Background";
import { Navigate } from "react-router-dom";

import Message, { MessageInterface } from "../components/message";

interface Styles {
    [key: string]: React.CSSProperties;
}


export default function Pin({ user, setProfile }: any) {

    const [pin, setPin] = useState<string>("")
    const [access, setAccess] = useState<boolean>(false)
    const [loginMessage, setLoginMessage] = useState<MessageInterface | null>(
        null
    );

    const [entries, setEntries] = useState(["", "", "", ""])

    useEffect(() => {
        if(pin == user?.pin?.pin){
            setAccess(true)
        } else{
            setAccess(false)
        }
    }, [pin])




    return (
        <Background>
            {user?.pin?.enabled ? (
                <>
                    {access ? (<Navigate to={"/select"} replace />) : (
                        <div style={styles.container}>
                            <h1 style={{ marginBottom: "auto", marginTop: 50 }}>Please Enter Your Pin</h1>
                            <form style={styles.form}>
                                <input
                                    id="Pin"
                                    style={{ opacity: "0" }}
                                    type="password"
                                    value={pin}
                                    onChange={(e) => {
                                        if (e.currentTarget.value.length <= 4) {
                                            setPin(e.currentTarget.value);
                                        }

                                    }}
                                    autoFocus={true}
                                    autoCorrect="false"
                                    autoComplete="false"
                                />
                                <div style={styles.inputGroup}>
                                    {entries.map((entry: any, index: number) => (
                                        <label
                                            key={index}
                                            style={{ ...styles.entry, borderColor: pin.length == index ? '#0090ff90' : "#ffffff20" }}
                                            htmlFor="Pin"
                                        >{pin[index]}</label>
                                    ))}
                                </div>
                            </form>
                        </div>
                    )}

                </>
            ) : (
                <Navigate to={"/select"} replace />
            )}

        </Background>
    )
}

const styles: Styles = {
    container: {
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    form: {
        width: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    inputGroup: {
        marginBottom: "300px",
        display: "flex",
        gap: "10px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    input: {
        padding: "8px 15px",
        fontSize: "14px",
        flex: "1",
        border: "2px solid #ffffff20",
        borderRadius: "10px",
        backgroundColor: "#00000050",
        color: "white",
        outline: "none !important",
    },
    entry: {
        // padding: "15px 0px",
        fontSize: "50px",
        flex: "1",
        border: "2px solid #ffffff20",
        borderRadius: "10px",
        backgroundColor: "#00000050",
        color: "white",
        outline: "none !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 29,
        padding: "0px 12.5px",
        height: 54
    },
    button: {
        padding: "10px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
        cursor: "pointer",
        border: "2px solid #ffffff20",
        borderRadius: "10px",
        backgroundColor: "#fff",
        width: "100%",
    },
    links: {
        margin: "10px",
        fontSize: "12px",
        color: "#ffffff90",
        textDecoration: "none",
    },
};
