import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { AnySections } from "../components/Sections";
import { ref, onValue, remove, push, set, get } from "@firebase/database";
import { database } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faAdd, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

interface Styles {
  [key: string]: React.CSSProperties;
}

async function addToLibrary(uid: string | undefined | null, profileIndex: number, contentId: string) {
  const LibraryAddedRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/added/`);

  // Check if the contentId already exists under any key
  const snapshot = await get(LibraryAddedRef);

  if (snapshot.exists()) {
    let exists = false;

    // Loop through the snapshot to find if contentId exists under any key
    snapshot.forEach((childSnapshot: any) => {
      if (childSnapshot.val() === contentId) {
        exists = true;
        // If contentId exists, remove it
        remove(childSnapshot.ref);
      }
    });

    // If contentId doesn't exist, add it
    if (!exists) {
      const newPostRef = push(LibraryAddedRef);
      set(newPostRef, contentId);
    }
  } else {
    // If the library is empty, add the contentId
    const newPostRef = push(LibraryAddedRef);
    set(newPostRef, contentId);
  }

  const LibraryRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/`);
  const libSnapshot = await get(LibraryRef);

  return libSnapshot.val()
}

function getContent(id: string, onResult = (contents: any) => { }) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    onResult(Object.values(contents).find((item: any) => item.uniqueId === id));
  });
}
function getContents(id: string, onResult = (contents: any) => { }) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    // const currentContent = Object.values(contents).find(
    //   (item: any) => item.uniqueId === id
    // );
    const filteredContents = Object.values(contents).filter(
      (item: any) => item.status === "onAir" && item.content_type === "Film"
    );
    onResult(filteredContents);
  });
}

function MovieModal({ movieId }: any) {
  const { user, profile, library, setLibrary } = useAuth()
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [content, setContent] = useState<any>();
  const [contents, setContents] = useState<any[]>([]);

  useEffect(() => {
    getContent(movieId, (movie: any) => {
      setContent(movie);
    });

    getContents(movieId, (movies: any[]) => {
      setContents(movies);
    });
  }, [movieId]);

  const styles: Styles = {
    container: {
      height: "100%",
      backgroundImage: "linear-gradient(0deg, #00000050, #00000090)",
      display: winWidth > 720 ? "flex" : "",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "center",
      overflowY: "scroll",
    },

    backdrop: {
      width: "100%",
      aspectRatio: 16 / 6,
      flexShrink: 0,
      backgroundImage: `url(${content?.backdrop})`,
      backgroundSize: "cover",
    },
    faded: {
      height: "100%",
      width: "100%",
      backgroundImage: `linear-gradient(90deg, #00000050, transparent)`,
      backgroundSize: "cover",
    },
    logo: {
      width: "300px",
      height: "100px",
      margin: "100px 0px 50px 50px",
      objectFit: "contain",
    },
    about: {
      maxWidth: "40%",
      margin: "20px 50px",
    },
    overview: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 3,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      margin: "50px 20px 10px 50px",
    },

    watchButton: {
      gap: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 30px",
      borderRadius: "10px",
      background: "white",
      textDecoration: "none",
      color: "black",
      border: "none",
    },

    addButton: {
      height: "38px",
      width: "38px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      padding: "10px",
      borderRadius: "100px",
      backgroundColor: "white",
      color: "black",
      border: "none",
    },
    sections: {
      width: "100%",
    },
  };

  return (
    <Modal backgroundTheme={`url(${content?.backdrop})`} name={content?.title}>
      <div style={styles.container}>
        <div style={styles.backdrop}>
          <div style={styles.faded}>
            <img style={styles.logo} src={content?.logo} alt="logo" />
            <div style={styles.about}>
              <b>
                <span>{`${content?.rating}`}</span>
                {` ${new Date(content?.released).getFullYear()} ‧ ${content?.tag
                  } ‧ ${content?.length}`}
              </b>
              <p style={styles.overview}>{content?.overview}</p>
            </div>
            <div style={styles.buttons}>
              {/* <div style={styles.watchButton}> */}
              <Link
                style={styles.watchButton}
                to={`/watch/${content?.uniqueId}`}
              >
                <FontAwesomeIcon icon={faPlay} size="sm" color="black" />
                <p style={{ textDecoration: "none" }}>{user ? (library?.progress && Object.values(library?.progress)?.find((item:any)=> item.uniqueId === content?.uniqueId)? "Resume":"Watch Now") : "Sign in"}</p>
              </Link>
              {/* </div> */}

              {user && library && (
                <button style={styles.addButton} onClick={() => {
                  addToLibrary(user.uid, Object.values(user?.profiles || []).findIndex((items) => items.username === profile.username) || 0, content?.uniqueId).then((data: any) => {
                    setLibrary(data)
                  })
                }}>
                  <FontAwesomeIcon icon={library?.added ? Object.values(library?.added)?.includes(content?.uniqueId) ? faCheck : faAdd : faAdd} size="sm" color="black" />
                </button>
              )}
            </div>
            {library?.progress && Object.values(library?.progress)?.map((progress: any) =>

            (
              <>
                {progress.uniqueId === content?.uniqueId && (
                  <div onClick={() => { console.log(progress) }} style={{ height: "5px", width: "100%", backgroundColor: '#555555cc' }}>
                    <div style={{ height: "5px", width: `${(progress.currentTime / progress.durationTime) * 100}%`, backgroundColor: 'white' }}></div>
                  </div>
                )}
              </>

            )

            )}

            {/* {library?.progress && Object.values(library?.progress)?.map((progress: any, index) => (
              <>
                  < div onClick={()=>{console.log(progress)}} style={{ height: "5px", width: "100%", backgroundColor: '#555555cc' }}>
                    <div style={{ height: "5px", width: `${(progress.currentTime / progress.durationTime)*100}%`, backgroundColor: 'white' }}>{progress.uniqueId}</div>
                  </div >
              </>
            ))} */}

          </div>
        </div>
        <div style={styles.sections}>
          {content?.keywords && (
            <AnySections
              array={contents.filter(item => {
                if (item.keywords) {
                  const itemKeywords = item.keywords.split(',');
                  return itemKeywords.some((keyword: any) => content.keywords.split(',').includes(keyword));
                }
                return false; // or handle case when item.keywords is falsy
              })}
              name="You may also like"
            />
          )}
        </div>
      </div>
    </Modal >
  );
}

export default MovieModal;
