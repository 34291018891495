import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/plinxlogo.png"; // Import your website logo
import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import { ref, onValue, remove, push, set, get } from "@firebase/database";
import { database } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";


function searchContents(name: string): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const starCountRef = ref(database, "contents/");
    onValue(starCountRef, (snapshot) => {
      const contents = snapshot.val();
      if (contents) {
        const filteredContents = Object.values(contents).filter(
          (item: any) => item.status === "onAir" && item.title.toLowerCase().includes(name.toLowerCase())
        );
        resolve(filteredContents);
      } else {
        resolve([]);
      }
    }, (error) => {
      reject(error);
    });
  });
}


const Header = ({ transparency = true }: any) => {
  const { isAuthenticated, user, profile, setProfile } = useAuth();
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // const [profile, setProfile] = useState<any>(user?.profiles[getProfile() || 0]);
  const [searchMode, setSearchMode] = useState(false)
  const [results, setResults] = useState<any[]>([])
  const [searchQuery, setQuery] = useState("")

  useEffect(() => {
    searchContents(searchQuery).then((results) => {
      setResults(results)
    })
  }, [searchQuery])


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <header
      style={{
        ...styles.header,
        backgroundColor: transparency ? "#000000" : "transparent",
      }}
    >
      <div style={styles.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Plinx Logo" style={styles.logo} />
        </Link>
      </div>
      {winWidth > 640 && (
        <>
          {!searchMode ? (
            <nav style={styles.nav}>
              <ul style={styles.navList}>
                <li style={styles.navItem}>
                  <Link to="/" style={styles.navLink}>
                    Home
                  </Link>
                </li>
                <li style={styles.navItem}>
                  <Link to="/movies" style={styles.navLink}>
                    Movies
                  </Link>
                </li>
                <li style={styles.navItem}>
                  <Link to="/tv" style={styles.navLink}>
                    TV Shows
                  </Link>
                </li>
                <li style={styles.navItem}>
                  <Link to="/music" style={styles.navLink}>
                    Music
                  </Link>
                </li>
                {profile && (
                  <li style={styles.navItem}>
                    <Link to="/library" style={styles.navLink}>
                      Library
                    </Link>
                  </li>
                )}

                <button id="searchButton" style={{ ...styles.navItem, marginLeft: 10, display:"flex",gap: 10, cursor: "pointer", backgroundColor: 'transparent', border: 'none' }} onClick={() => { setSearchMode(true) }}>
                  <FontAwesomeIcon icon={faSearch} color="#ffffff90" size="sm" />
                  <span style={styles.navLink}>
                    Search
                  </span>
                </button>
              </ul>
            </nav>) : (
            <div id="searchContainer" style={styles.searchContainer}>
              <div style={styles.searchBar}>
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faArrowLeft} color="#ffffff90" size="xs" onClick={() => { setSearchMode(false) }} />
                <input style={styles.searchInput} type="text" placeholder="Search Plinx..." onChange={(e) => { setQuery(e.currentTarget.value) }} value={searchQuery} />
              </div>
              <div style={styles.searchResult}>

                {results.map((result) => (
                  <Link to={result.content_type === 'Film' ? `/?movie=${result.uniqueId}` : (result.content_type === 'TV Shows' ? `/?tv=${result.uniqueId}` : `/?music=${result.uniqueId}`)} className="item search" style={{ display: 'flex', alignItems: 'center', margin: 5, gap: 10, borderRadius: 5, cursor: 'pointer' }}>
                    <img style={{ height: 75, aspectRatio: 2 / 3, borderRadius: 5 }} src={result.poster} alt={result.title} />
                    <div style={{ display: 'flex', flexDirection: 'column', margin: 5, gap: 10 }}>
                      <p>{result.title}</p>
                      <p style={{ color: "#ffffff80" }}>{result.content_type}</p>
                    </div>
                  </Link>
                ))}

              </div>
            </div>
          )}
        </>
      )}

      <div style={styles.userActions}>
        {/* Add user authentication buttons or user profile section */}
        {user ? (
          <Link to="/profile" style={styles.navLink}>
            <img
              style={{
                height: "30px",
                aspectRatio: 1,
                borderRadius: "10px",
              }}
              src={profile?.profileUrl}
              alt=""
            />
            {winWidth > 640 && (
              <b style={{ marginLeft: "10px" }}>{profile?.username}</b>
            )}
          </Link>
        ) : (
          <Link to="/login" style={styles.auth}>
            Login
          </Link>
        )}
      </div>
      {user && !profile && <Navigate to={"/profile"} replace />}
    </header>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  header: {
    backgroundColor: "#00000000",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 40px",
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    transition: ".5s",
    zIndex: 2,
  },
  logoContainer: {
    flex: "0 0 auto",
  },
  logo: {
    height: "24px",
    width: "auto",
  },
  nav: {
    flex: "1 1 auto",
    display: "flex",
  },
  navList: {
    listStyle: "none",
    display: "flex",
    margin: 0,
    padding: "0px 50px",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 20,
    flex: 1,
  },
  navItem: {
    // marginRight: "20px",
  },
  navLink: {
    textDecoration: "none",
    color: "#ffffff80",
    fontSize: "medium",
    display: "flex",
    alignItems: "center",
  },
  userActions: {
    flex: "0 0 auto",
  },
  auth: {
    textDecoration: "none",
    color: "black",
    backgroundColor: "white",
    padding: "5px 20px",
    borderRadius: "50px",
    fontSize: "14px",
    // fontWeight: "bold",
  },
  searchContainer: {
    width: "500px",
  },
  searchBar: {
    border: '1px solid #ffffff80',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 15px',
  },
  searchInput: {
    padding: '0px 15px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: 'white',
    flex: 1
  },
  searchResult: {
    maxHeight: "300px",
    width: "480px",
    position: 'absolute',
    backgroundColor: 'black',
    padding: "10px",
    overflow: 'hidden',
    overflowY: 'scroll'
  }
};

export { Header };
