import React from "react";
import Background from "./Background";

interface Styles {
    [key: string]: React.CSSProperties;
  }

export default function Loading({ logo = false, loadingMessage = "" }) {
  return (
    <>
      <Background />
      <div style={style.container}>
        <div style={style.loaders}>
          <span style={{ ...style.loader }}></span>
          <span
            style={{
              ...style.loader,
              backgroundColor: "white",
              height: "15px",
              animationDelay: ".5s",
            }}
          ></span>
          <span style={{ ...style.loader, animationDelay: "2s" }}></span>
        </div>
        <p style={style.loadingMessage}>{loadingMessage}</p>
      </div>
    </>
  );
}

const style: Styles = {
  container: {
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    justifyContent: "center",
  },
  loaders: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    gap: "0px",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  loader: {
    position: "absolute",
    width: "40px",
    height: "10px",
    backgroundColor: "#0090ff",
    animation: "loading infinite 3s",
    borderRadius: "10px"
  },
  loadingMessage: {
    position: "absolute",
    color: "#ffffff90",
    fontSize: "12px",
    fontWeight: "bold",
    bottom: "100px",
    textAlign:"center",
    width: "100vw",
  },
};
