import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BottomNavBar from "../components/BottomNavBar";
import Loading from "../components/Loading";
import MusicModal from "../modals/music";
import MovieModal from "../modals/movie";
import TVModal from "../modals/tv";

import { ref, onValue } from "@firebase/database";
import { database } from "../firebase";

import Background from "../components/Background";
import { Header } from "../components/Header";
import {
  FeatureSection,
  MovieSections,
  MusicSections,
  TVSections,
  AnySections
} from "../components/Sections";

function getContents(onResult = (contents: any) => { }) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    onResult(contents);
  });
}

export default function Home({ setMiniPlayer }: any) {
  const [scroll, setScroll] = useState(0);
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [contents, setContents] = useState<any[] | null>(null);

  useEffect(() => {
    setMiniPlayer(true);
    getContents((data) => {
      setContents(
        Object.values(data).filter((items: any) => items.status === "onAir" && items.content_type === "Song")
      );
    });
  }, []);

  const [searchParams] = useSearchParams();
  const musicId = searchParams.get("music");
  const movieId = searchParams.get("movie");
  const tvId = searchParams.get("tv");

  const [genres, setGenres] = useState([])


  useEffect(() => {

    const g: any = {}
    contents?.flatMap((item) => {
      // Use a Set to store unique genres
      const uniqueGenres = new Set(item.genres);
      // Map over unique genres to render MovieSections components
      Array.from(uniqueGenres).map((genre: any, index: number) => {
        g[genre.trim()] = genre.trim()
      });
    })
    setGenres(Object.values(g))
  }, [contents])
  return (
    <>
      {contents ? (
        <>
          <Background>
            <Header transparency={scroll > 1} />
            <div
              style={{
                height: "calc(100vh - 200px)",
                width: "100vw",
                overflow: "hidden",
                overflowY: "scroll",
                padding: "100px 0px",
              }}
              onScroll={(e) => {
                setScroll(e.currentTarget.scrollTop);
              }}
            >
              {/* <FeatureSection content={contents ? contents[contents.length - 1] : []} /> */}

              {genres.map((genre: string, index: number) => (
                <AnySections
                  key={`${genre}_${index}`} // Ensure a unique key combination
                  name={genre}
                  array={contents?.filter(items => items.genres.includes(genre)).reverse()}
                />
              ))}

            </div>
            {winWidth < 640 && <BottomNavBar />}{" "}
          </Background>
          {musicId && <MusicModal musicId={musicId} />}
          {movieId && <MovieModal movieId={movieId} />}
          {tvId && <TVModal tvId={tvId} />}
        </>
      ) : (
        <>
          <Header transparency={scroll > 1} />
          <Loading />
        </>
      )}
    </>
  );
}
