import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BottomNavBar from "../components/BottomNavBar";
import Loading from "../components/Loading";
import MusicModal from "../modals/music";
import MovieModal from "../modals/movie";
import TVModal from "../modals/tv";

import { Navigate } from "react-router-dom";

import { ref, onValue } from "@firebase/database";
import { database } from "../firebase";

import Background from "../components/Background";
import { Header } from "../components/Header";
import {
  FeatureSection,
  MovieSections,
  MusicSections,
  TVSections,
} from "../components/Sections";
import { useAuth } from "../context/AuthContext";

function getContents(onResult = (contents: any) => { }) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    onResult(contents);
  });
}


export default function Library({ setMiniPlayer }: any) {

  const { isAuthenticated, user, profile, library} = useAuth();
  const [scroll, setScroll] = useState(0);
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [contents, setContents] = useState<any[] | null>(null);

  const [added, setAdded] = useState<any[] | null>(null);

  useEffect(() => {
    setMiniPlayer(true);
    getContents((data) => {
      setContents(
        Object.values(data).filter((items: any) => items.status == "onAir")
      );
    });

    if (profile?.library?.added) {
      setAdded(Object.values(profile?.library?.added))
    }
  }, [profile]);


  const [searchParams] = useSearchParams();
  const musicId = searchParams.get("music");
  const movieId = searchParams.get("movie");
  const tvId = searchParams.get("tv");
  return (
    <>{profile ? (
      <>
        {library && library?.added? (
          <>

            {contents ? (
              <>
                <Background>
                  <Header transparency={scroll > 1} />
                  <div
                    style={{
                      height: "calc(100vh - 200px)",
                      width: "100vw",
                      overflow: "hidden",
                      overflowY: "scroll",
                      padding: "100px 0px",
                    }}
                    onScroll={(e) => {
                      setScroll(e.currentTarget.scrollTop);
                    }}
                  >
                    <FeatureSection content={contents?.filter(
                      (items) => items.content_type != "Song" && Object.values(library?.added)?.some((e:any) => items.uniqueId == e)
                    ).reverse()[0]} />
                    <MusicSections
                      name="Music I liked"
                      array={contents?.filter(
                        (items) => items.content_type == "Song" && Object.values(library?.added)?.some((e:any) => items.uniqueId == e)
                      )}
                    />
                    <TVSections
                      name="TVs I added"
                      array={contents?.filter(
                        (items) => items.content_type == "TV Shows" && Object.values(library?.added)?.some((e:any) => items.uniqueId == e)
                      )}
                    />
                    <MovieSections
                      name="Movies I added"
                      array={contents?.filter(
                        (items) => items.content_type == "Film" && Object.values(library?.added)?.some((e:any) => items.uniqueId == e)
                      )}
                    />
                  </div>
                  {winWidth < 640 && <BottomNavBar />}{" "}
                </Background>
                {musicId && <MusicModal musicId={musicId} />}
                {movieId && <MovieModal movieId={movieId} />}
                {tvId && <TVModal tvId={tvId} />}
              </>
            ) : (
              <>
                <Header transparency={scroll > 1} />
                <Loading />
              </>
            )}
          </>
        ) : (<Navigate to="/select" />)}
      </>

    ) : (
      <Navigate to={"/profile"} replace />
    )}</>

  );
}
