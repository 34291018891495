import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faBackwardStep,
  faChevronLeft,
  faForwardStep,
  faRepeat,
  faShuffle,
} from "@fortawesome/free-solid-svg-icons";

import { useParams, useNavigate } from "react-router-dom";
import { MusicSections } from "../components/Sections";
import { ref, onValue } from "@firebase/database";
import { database } from "../firebase";

interface Styles {
    [key: string]: React.CSSProperties;
}

function getContent(id:string, onResult = (contents: any) => {}) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    onResult(Object.values(contents).find((item: any) => item.uniqueId == id));
  });
}

function getSimilarContents(id:string, onResult = (contents: any) => {}) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    const thisContent: any = Object.values(contents).find(
      (item: any) => item.uniqueId == id
    );
    onResult(
      Object.values(contents).filter((item: any) =>
        String(item.artist)
          .split(" feat ")
          .some((artist) =>
            String(thisContent.artist).split(" feat ").includes(artist.trim())
          )
      )
    );
  });
}

function timeFormatter(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

function Listening({ video, setVideo, status, setStatus, setMiniPlayer }: any) {
  const { musicId } = useParams();
  const navigate = useNavigate();
  const [song, setSong] = useState<any>();

  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [content, setContent] = useState<any>();
  const [contents, setContents] = useState<any[]>();

  useEffect(() => {
    setMiniPlayer(false);
    if (video?.content.uniqueId != musicId) {
      getContent(musicId || "", (music: any) => {
        setContent(music);
        // console.log(video);
        video?.videoElem.pause();
        const videoElem: any = document.createElement("video");
        // videoElem.autoplay = true;
        videoElem.src = music.songUrl;
        videoElem.poster = music.thumbnail;
        videoElem.title = `${music.artist} - ${music.title}`;
        setSong(videoElem);
        setVideo({ content: music, videoElem });
        videoElem.ontimeupdate = (e: any) => {
          const s = videoElem;
          setStatus({
            duration: s.duration,
            currentTime: s.currentTime,
            isPlaying: !s.paused,
            isLoop: s.loop,
            redirecting: `/listening/${music.uniqueId}`,
          });
        };

        videoElem.onloadeddata = (e: any) => {
          const s = videoElem;
          setStatus({
            duration: s.duration,
            currentTime: s.currentTime,
            isPlaying: !s.paused,
            isLoop: s.loop,
            redirecting: `/listening/${music.uniqueId}`,
          });
        };
      });
    } else {
      setContent(video?.content);
    }
    getSimilarContents(musicId||"", (music: any) => {
      setContents(music);
    });
  }, [musicId]);

  const styles: Styles = {
    body: {
      backgroundImage: `url(${content?.thumbnail})`,
      height: "100vh",
      width: "100vw",
      backgroundSize: "100000%",
    },
    container: {
      backgroundImage: `linear-gradient(90deg, #00000050, #00000090)`,
      height: "100vh",
      width: "100vw",
      backgroundSize: "100000%",
      display: "flex",
      alignItems: "center",
      justifyContent: winWidth > 720 ? "space-around" : "",
      flexDirection: winWidth > 720 ? "row" : "column",
      overflow: winWidth > 720 ? "hidden" : "scroll",
    },
    album: {
      //   flex: 1,
      flexDirection: winWidth > 720 ? "row" : "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: winWidth > 720 ? "0" : "100px",
    },

    artwork: {
      width: winWidth > 720 ? "200px" : "70vw",
      aspectRatio: 1,
      marginBottom: "20px",
      borderRadius: "10px",
      margin: winWidth > 720 ? "0px" : "0px 10vw",
    },

    titles: {
      margin: "10px",
    },

    current: {
      display: "flex",
      alignItems: winWidth > 720 ? "" : "center",
      justifyContent: "center",
      flexDirection: "column",
    },

    controlbuttonsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      marginTop: "50px",
      width: "200px",
    },

    controlbuttons: {
      height: "40px",
      aspectRatio: 1,
      borderRadius: 100,
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      background: "transparent",
    },

    seekBarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      marginTop: "50px",
      width: "100%",
    },

    seekBar: {
      backgroundColor: "#ffffff50",
      width: "calc(100% - 80px)",
      height: "5px",
      borderRadius: "10px",
      overflow: "hidden",
      transition: ".2s",
    },

    seek: {
      height: "100%",
      width: `${(status.currentTime / status.duration) * 100}%`,
      backgroundColor: "#ffffff",
    },

    more: {
      backgroundColor: "#55555550",
      borderRadius: "10px",
      minHeight: "80vh",
      maxWidth: "100vw",
      margin: winWidth > 720 ? "0px" : "100px auto",
    },

    back: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "transparent",
      border: "none",
      padding: "20px",
      cursor: "pointer",
    },
  };

  function seek(time: number, video: any) {
    video.videoElem.currentTime = time;
  }

  function playtoggle() {
    if (status.isPlaying) {
        video.videoElem.pause();
      setStatus({ ...status, isPlaying: false });
    } else {
        video.videoElem.play();
      setStatus({ ...status, isPlaying: true });
    }
  }

  const setRepeat = () => {
    if (!video.videoElem.loop) {
        video.videoElem.loop = true;
      setStatus({ ...status, isLoop: true });
    } else {
        video.videoElem.loop = false;
      setStatus({ ...status, isLoop: false });
    }
  };

  return (
    <>
      <div style={styles.body}>
        <div style={styles.container}>
          <div style={styles.current}>
            <div style={styles.album}>
              <img style={styles.artwork} src={content?.thumbnail} />
              <div style={styles.titles}>
                <h3>{content?.title}</h3>
                {/* <b>{content?.album}</b> */}
                <p>{content?.artist}</p>
              </div>
            </div>
            <div style={styles.controlbuttonsContainer}>
              <button
                style={styles.controlbuttons}
                onClick={() => {
                  seek(status.duration, video);
                }}
              >
                <FontAwesomeIcon icon={faShuffle} size="sm" color="white" />
              </button>
              <button
                style={styles.controlbuttons}
                onClick={() => {
                  seek(0, video);
                }}
              >
                {/* <i className="fas fa-backward-step"></i> */}
                <FontAwesomeIcon
                  icon={faBackwardStep}
                  size="lg"
                  color="white"
                />
              </button>
              <button style={styles.controlbuttons} onClick={playtoggle}>
                <FontAwesomeIcon
                  icon={status.isPlaying ? faPause : faPlay}
                  size="xl"
                  color="white"
                />
              </button>
              <button
                style={styles.controlbuttons}
                onClick={() => {
                  seek(status.duration, video);
                }}
              >
                <FontAwesomeIcon icon={faForwardStep} size="lg" color="white" />
              </button>
              <button
                onClick={setRepeat}
                style={{
                  ...styles.controlbuttons,
                  opacity: status.isLoop ? 1 : 0.2,
                }}
              >
                <FontAwesomeIcon icon={faRepeat} size="sm" color="white" />
              </button>
            </div>
            <div style={styles.seekBarContainer}>
              <p>{timeFormatter(status.currentTime)}</p>
              <div
                style={styles.seekBar}
                className="seekbar"
                onClick={(e) => {
                  const x = e.clientX - e.currentTarget.offsetLeft;
                  const width = e.currentTarget.clientWidth;
                  const position = (x / width) * status.duration;
                  seek(position, video);
                }}
              >
                <div style={styles.seek}></div>
              </div>
              <p>{timeFormatter(status.duration)}</p>
            </div>
          </div>
          <div style={styles.more}>
            <MusicSections column={0} name="Songs" array={contents} />
          </div>
        </div>
      </div>
      <button
        style={styles.back}
        onClick={() => {
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="sm" color="white" />
      </button>
    </>
  );
}

export default Listening;
