import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import Background from "../components/Background";
import Logo from "../components/Logo";
import { useAuth } from "../context/AuthContext";

import { Navigate } from "react-router-dom";
import Message, { MessageInterface } from "../components/message";

interface Styles {
    [key: string]: React.CSSProperties;
}

export default function Login() {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState<MessageInterface | null>(
    null
  );

  const { login, isAuthenticated, user } = useAuth();

  function loginHandle(e: any, email: string, password: string) {
    e.preventDefault();

    login(email, password).then(
      () => {
        setLoginMessage({
          type: "succeed",
          title: "Great",
          body: "Login Succeed",
        });
      },
      (error) => {
        setLoginMessage({
          type: "error",
          title: "Error",
          body: error.code.replace("auth/", "").replace(/\-/g, " "),
        });
      }
    );

    setTimeout(() => {
      setLoginMessage(null);
    }, 5000);
  }

  // console.log(user);

  return (
    <Background>
      <div style={styles.container}>
        <Logo style={{ marginBottom: "100px" }} />
        <form style={styles.form}>
          <div style={styles.inputGroup}>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              style={styles.input}
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              style={styles.input}
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              autoCorrect="false"
            />
            <input
              type="checkbox"
              id="show-password"
              checked={isPasswordVisible}
              onChange={(e) => {
                setPasswordVisibility(e.currentTarget.checked);
              }}
            />
            {/* <label htmlFor="show-password">Show Password</label> */}
          </div>
          <Link
            to="/forgot-password"
            style={{ ...styles.links, margin: "0px 0px 10px auto" }}
          >
            Forgot Password?
          </Link>
          <button
            type="submit"
            style={styles.button}
            onClick={(e) => loginHandle(e, email, password)}
          >
            Login
          </button>
          <Link to="/signup" style={styles.links}>
            I never had an account
          </Link>
        </form>
      </div>
      {loginMessage && (
        <Message
          type={loginMessage.type}
          title={loginMessage.title}
          body={loginMessage.body}
        />
      )}

      {user && <Navigate to="/" replace />}
    </Background>
  );
}

const styles: Styles = {
  container: {
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  inputGroup: {
    marginBottom: "15px",
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  input: {
    padding: "8px 15px",
    fontSize: "14px",
    flex: "1",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#00000050",
    color: "white",
    outline: "none !important",
  },
  button: {
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "100%",
  },
  links: {
    margin: "10px",
    fontSize: "12px",
    color: "#ffffff90",
    textDecoration: "none",
  },
};
