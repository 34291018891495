import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BottomNavBar from "../components/BottomNavBar";
import Loading from "../components/Loading";
import MusicModal from "../modals/music";
import MovieModal from "../modals/movie";
import TVModal from "../modals/tv";

import { Navigate, Link } from "react-router-dom";

import { ref, onValue, get, set, push, remove } from "@firebase/database";
import { database } from "../firebase";

import Background from "../components/Background";
import { Header } from "../components/Header";
import {
  FeatureSection,
  MovieSections,
  MusicSections,
  TVSections,
} from "../components/Sections";
import { useAuth } from "../context/AuthContext";

function getContents(onResult = (contents: any) => { }) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot) => {
    const contents = snapshot.val();
    onResult(contents);
  });
}

// function addToLibrary(uid: string, profileIndex: number, contentId: string) {
//   const LibraryCountRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/added/`);

//   const newPostKey = push(LibraryCountRef, contentId)
// }

async function addToLibrary(uid: string|undefined|null, profileIndex: number, contentId: string) {
  const LibraryCountRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/added/`);

  // Check if the contentId already exists under any key
  const snapshot = await get(LibraryCountRef);

  if (snapshot.exists()) {
    let exists = false;

    // Loop through the snapshot to find if contentId exists under any key
    snapshot.forEach(childSnapshot => {
      if (childSnapshot.val() === contentId) {
        exists = true;
        // If contentId exists, remove it
        remove(childSnapshot.ref);
      }
    });

    // If contentId doesn't exist, add it
    if (!exists) {
      const newPostRef = push(LibraryCountRef);
      set(newPostRef, contentId);
    }
  } else {
    // If the library is empty, add the contentId
    const newPostRef = push(LibraryCountRef);
    set(newPostRef, contentId);
  }

  const LibraryRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/`);
  const libSnapshot = await get(LibraryRef);

  return libSnapshot.val()
}


export default function Select({ setMiniPlayer }: any) {

  const { user, profile, library, setLibrary } = useAuth()
  const [added, setAdded] = useState<any[] | null>(null);
  const [scroll, setScroll] = useState(0);
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  const [highlights, setHighlights] = useState<any[]>([])
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);


  const [contents, setContents] = useState<any[] | null>(null);



  useEffect(() => {
    setMiniPlayer(true);
    getContents((data) => {
      setContents(
        Object.values(data).filter((items: any) => items.status == "onAir")
      );
    });

  }, [profile]);


  return (
    <>{profile ? (
      <>
        {profile?.library?.added ? (<Navigate to={"/"} replace />) : (
          <>
            {contents ? (
              <>
                <Background>
                  <div
                    style={{
                      height: "calc(100vh - 200px)",
                      width: "100vw",
                      overflow: "hidden",
                      overflowY: "scroll",
                      padding: "100px 0px",
                    }}
                    onScroll={(e) => {
                      setScroll(e.currentTarget.scrollTop);
                    }}
                  >
                    <div style={{ margin: "-50px 50px 50px 50px", display: "flex", justifyContent: "space-between", }}>
                      <h1 >Add Some In Your Library</h1>
                      <Link
                        type="submit"
                        style={styles.button}
                        to="/library"
                      // onClick={(e) => logoutHandle(e)}
                      >
                        Done
                      </Link>
                    </div>

                    <MovieSections
                      name="Movies"
                      array={contents?.filter(
                        (items) => items.content_type == "Film"
                      )}
                      highlight={highlights}


                      column={3}
                      overrideAction={(c: any, i: number) => {
                        
                        addToLibrary(user?.uid, Object.values(user?.profiles||[]).findIndex((items) => items.username == profile.username) || 0, c.uniqueId).then((data:any)=>{
                          setLibrary(data)
                          setHighlights(Object.values(data?.added) || c.uniqueId);

                        })
                      }
                      }
                    />
                  </div>

                </Background>
              </>
            ) : (
              <>
                <Loading />
              </>
            )}
          </>
        )}
      </>

    ) : (
      <Navigate to={"/profile"} replace />
    )}</>

  );
}

interface Styles {
  [key: string]: React.CSSProperties;
}



const styles: Styles = {
  container: {
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profilesList: {
    width: "60%",
    maxWidth: "500px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    margin: 50,
  },
  profiles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    border: "none",
    background: "transparent",
    color: "white",
  },
  avatars: {
    height: 70,
    width: 70,
    borderRadius: 10,
    backgroundColor: "#ffffff10",
  },
  button: {
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#fff",
    maxWidth: "100px",
    width: "80%",
  },
  links: {
    margin: "10px",
    fontSize: "12px",
    color: "#ffffff90",
    textDecoration: "none",
  },
};
