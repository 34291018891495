import React, { useState, useEffect } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";


interface Styles {
    [key: string]: React.CSSProperties;
  }

function Modal({ children, backgroundTheme = "", name = "" }: any) {
  const navigate = useNavigate();
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalTop, setModalTop] = useState(winWidth > 720 ? "5vh" : "0vh");

  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setModalTop(window.innerWidth > 720 ? "5vh" : "0vh");
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Styles = {
    modal: {
      position: "fixed",
      backgroundColor: "#000000ea",
      height: "100vh",
      width: "100vw",
      left: 0,
      top: 0,
      display: "flex",
      zIndex: 5,
    },
    content: {
      position: "relative",
      backgroundColor: "#111",
      minHeight: "90vh",
      height: winWidth > 720 ? "90vh" : "100vh",
      width: winWidth > 720 ? "80vw" : "100vw",
      margin: "0px auto",
      borderRadius: winWidth > 720 ? 10 : 0,
      overflow: "hidden",
      backgroundSize: "1000000%",
      display: "flex",
      flexDirection: "column",
      top: modalTop,
      transition: ".2s",
    },
    close: {
      cursor: "pointer",
      userSelect: "none",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.modal}>
      <div
        role="dialog"
        style={{
          ...styles.content,
          backgroundImage: backgroundTheme,
        }}
      >
        <div
          style={styles.close}
          onMouseUp={() => {
            // setModalTop("100vh");
            setTimeout(() => {
              navigate(-1);
            }, 100);
            // navigate(-1);
          }}
        >
          <span
            style={{
              fontSize: "28px",
            }}
          >
            &times;
          </span>
          <p
            style={{
              margin: "auto",
              paddingRight: "32px",
            }}
          >
            {name}
          </p>
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
