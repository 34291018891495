import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

interface Styles {
    [key: string]: React.CSSProperties;
  }

export function FeatureSection({ content }:any) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Styles  = {
    container: {
      display: "flex",
      color: "#fff",
      paddingBottom: "50px",
      width: "100vw",
      justifyContent: "center",
    },
    title: {
      fontSize: "24px",
      marginBottom: "10px",
    },
    description: {
      fontSize: "16px",
    },
    display: {
      width:
        windowWidth >= 560 ? (windowWidth >= 1024 ? "80vw" : "50vw") : "60vw",
      aspectRatio:
        windowWidth >= 560 ? (windowWidth >= 1024 ? 3 / 1 : 16 / 9) : 2 / 3,
      borderRadius: 10,
      overflow: "hidden",
      objectFit: "cover",
    },

    logo: {
      transition: "1s transform eas-out",
    //   transform: "translateX(0%)",
      position: "relative",
      width: "40%",
      height: "100%",
      objectFit: "contain",
      top: "-100%",
      transform: "translateX(10%)",
    },
  };

  return (
    <div style={styles.container}>
      <Link
        className="feature"
        style={styles.display}
        to={
          content.content_type === "Film"
            ? `/?movie=${content.uniqueId}`
            : content.content_type === "TV Shows"
            ? `/?tv=${content.uniqueId}`
            : content.content_type === "Song"
            ? `/?music=${content.uniqueId}`
            : "/404"
        }
      >
        {windowWidth > 560 ? (
          <>
            <img
              src={content.wide_backdrop ? content.wide_backdrop : content.backdrop}
              style={styles.display}
              alt={content.title}
            />
            <img src={content.logo} style={styles.logo} alt={content.title} />
          </>
        ) : (
          <img
            src={windowWidth > 560 ? ( content.backdrop) : content.poster}
            style={styles.display}
            alt={content.title}
          />
        )}
      </Link>
    </div>
  );
}

export function MusicSections({
  name = "Music",
  array = [],
  column = -1,
  overridepath = "",
}:any) {
  return (
    <>
      {column >= 0 ? (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div
                style={{
                  ...styles.sectionCol,
                  height: column === 0 ? "auto" : `${column * 70}px`,
                }}
              >
                {array.map((content: any, index: number) => (
                  <Link
                    className="item"
                    to={
                      overridepath
                        ? overridepath
                        : `/?music=${content.uniqueId}`
                    }
                    style={styles.itemRow}
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterSquare, height: "50px" }}
                      src={content.poster}
                    />
                    <div style={styles.itemDetail}>
                      <b>{content.title}</b>
                      <p>{content.artist}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div style={styles.sectionRow}>
                {array.map((content:any, index:number) => (
                  <Link
                    className="item"
                    to={
                      overridepath
                        ? overridepath
                        : `/?music=${content.uniqueId}`
                    }
                    style={styles.itemCol}
                    key={index}
                  >
                    <img style={styles.posterSquare} src={content.poster} />
                    <div style={styles.itemDetail}>
                      <b>{content.title}</b>
                      <p>{content.artist}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

// TV

export function TVSections({ name = "TV Shows", array = [], column = -1 }:any) {
  return (
    <>
      {column >= 0 ? (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div
                style={{
                  ...styles.sectionCol,
                  height: column === 0 ? "auto" : `${column * 120}px`,
                }}
              >
                {array.map((content:any, index:number) => (
                  <Link
                    className="item"
                    to={`/?tv=${content.uniqueId}`}
                    style={styles.itemRow}
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterLandscape, height: "100px" }}
                      src={content.poster}
                      alt={content.title}
                    />
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.overview}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div style={styles.sectionRow}>
                {array.map((content:any, index:number) => (
                  <Link
                    className="item"
                    to={`/?tv=${content.uniqueId}`}
                    style={styles.itemCol}
                    key={index}
                  >
                    {content.thumbnail ? (
                      <img
                        style={styles.thumbnail}
                        src={content.thumbnail}
                        alt={content.title}
                      />
                    ) : (
                      <div style={styles.backdrop}>
                        <img
                          style={styles.thumbnail}
                          src={content.backdrop}
                          alt={content.title}
                        />
                        <img
                          style={styles.minLogo}
                          src={content.logo}
                          alt={content.title}
                        />
                      </div>
                    )}
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

//Movies
export function MovieSections({ name = "Movies", array = [], column = -1, overrideAction, highlight = [] }:any) {
  return (
    <>
      {column >= 0 ? (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div
                style={{
                  ...styles.sectionCol,
                  height: column === 0 ? "auto" : `${column * 120}px`,
                }}
              >
                {array.map((content:any, index:number) => (
                  <>
                  {overrideAction ? (
                    <button
                    className="item"
                    onClick={(e)=>{overrideAction(content, index)}}
                    style={{
                      ...styles.itemRow,
                      backgroundColor: highlight.includes(content.uniqueId) ? "#ffffff20" : "transparent"
                    }}
                    
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterLandscape, height: "100px" }}
                      src={content.poster}
                      alt={content.title}
                    />
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.overview}
                      </p>
                    </div>
                  </button>
                  ):(
                    <>
                    <Link
                    className="item"
                    to={`/?movie=${content.uniqueId}`}
                    style={styles.itemRow}
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterLandscape, height: "100px" }}
                      src={content.poster}
                      alt={content.title}
                    />
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.overview}
                      </p>
                    </div>
                  </Link>
                  </>
                  )}
                  
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div style={styles.sectionRow}>
                {array.map((content:any, index:number) => (
                  <Link
                    className="item"
                    to={`/?movie=${content.uniqueId}`}
                    style={styles.itemCol}
                    key={index}
                  >
                    {content.thumbnail ? (
                      <img
                        style={styles.thumbnail}
                        src={content.thumbnail}
                        alt={content.title}
                      />
                    ) : (
                      <div style={styles.backdrop}>
                        <img
                          style={styles.thumbnail}
                          src={content.backdrop}
                          alt={content.title}
                        />
                        <img
                          style={styles.minLogo}
                          src={content.logo}
                          alt={content.title}
                        />
                      </div>
                    )}

                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}






// any

export function AnySections({ name = "Section", array = [], column = -1, overrideAction, highlight = [] }:any) {
  return (
    <>
      {column >= 0 ? (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div
                style={{
                  ...styles.sectionCol,
                  height: column === 0 ? "auto" : `${column * 120}px`,
                }}
              >
                {array.map((content:any, index:number) => (
                  <>
                  {overrideAction ? (
                    <button
                    className="item"
                    onClick={(e)=>{overrideAction(content, index)}}
                    style={{
                      ...styles.itemRow,
                      backgroundColor: highlight.some((item: number) => item === index) ? "#ffffff20" : "transparent"
                    }}
                    
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterLandscape, height: "100px" }}
                      src={content.poster}
                      alt={content.title}
                    />
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.overview}
                      </p>
                    </div>
                  </button>
                  ):(
                    <>
                    <Link
                    className="item"
                    to={content.content_type === 'Film'? `/?movie=${content.uniqueId}` : (content.content_type === 'TV Shows'? `/?tv=${content.uniqueId}` : `/?music=${content.uniqueId}`)}
                    style={styles.itemRow}
                    key={index}
                  >
                    <img
                      style={{ ...styles.posterLandscape, height: "100px" }}
                      src={content.poster}
                      alt={content.title}
                    />
                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                        }}
                      >
                        {content.tag}
                      </p>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {content.overview}
                      </p>
                    </div>
                  </Link>
                  </>
                  )}
                  
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {array[0] && (
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>{name}</h3>
              <div style={styles.sectionRow}>
                {array.map((content:any, index:number) => (
                  <Link
                    className="item"
                    to={content.content_type === 'Film'? `/?movie=${content.uniqueId}` : (content.content_type === 'TV Shows'? `/?tv=${content.uniqueId}` : `/?music=${content.uniqueId}`)}
                    style={styles.itemCol}
                    key={index}
                  >
                    {content.thumbnail ? (
                      <img
                      style={content.content_type === 'Film'? styles.thumbnail : (content.content_type === 'TV Shows'? styles.thumbnail : styles.posterSquare)}
                        // style={styles.thumbnail}
                        src={content.thumbnail}
                        alt={content.title}
                      />
                    ) : (
                      <div style={styles.backdrop}>
                        <img
                          style={styles.thumbnail}
                          src={content.backdrop}
                          alt={content.title}
                        />
                        <img
                          style={styles.minLogo}
                          src={content.logo}
                          alt={content.title}
                        />
                      </div>
                    )}

                    <div style={styles.itemDetail}>
                      <b
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          textAlign:"center"
                        }}
                      >
                        {content.title}
                      </b>
                      <p
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          color: "#ffffff90",
                          textAlign:"center"
                        }}
                      >
                        {content.genres[0]}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

// Styles object for the component

  

const styles: Styles = {
  section: {
    marginBottom: "20px",
  },
  sectionTitle: {
    margin: "10px 40px 10px 40px",
  },
  sectionCol: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "20px",
    padding: "10px 40px",
    overflow: "hidden",
    overflowX: "auto",
    width: "calc(100% - 80px)",
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "20px",
    padding: "10px 40px",
    overflow: "hidden",
    overflowX: "scroll",
  },
  itemCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    minWidth: "300px",
    border: "none",
    backgroundColor:"transparent",
    borderRadius: 10,
    textAlign:"left"
  },

  itemDetail: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    fontSize: 12,
    margin: 5,
  },

  posterSquare: {
    height: "150px",
    aspectRatio: 1,
    borderRadius: 10,
  },

  posterLandscape: {
    height: "150px",
    aspectRatio: 2 / 3,
    borderRadius: 10,
  },
  thumbnail: {
    height: "150px",
    aspectRatio: 16 / 9,
    borderRadius: 10,
  },
  backdrop: {
    height: "150px",
    aspectRatio: 16 / 9,
    borderRadius: 10,
    display: "inline-block",
  },
  minLogo: {
    position: "relative",
    width: "50%",
    height: "100%",
    objectFit: "contain",
    left: "10px",
    top: "-100%",
    display: "content",
  },
};
