import React, { useState, useEffect, useContext } from "react";
import { database } from "../firebase";
import { ref, get, set, update, push, remove } from "@firebase/database";
import { useAuth } from "../context/AuthContext";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile, isTablet } from "react-device-detect";

import VideoControl from "../components/VideoControls";

async function addToProgress(uid: string | undefined | null, profileIndex: number, contentId: string, status:any) {
    const progressRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/progress/${contentId}`)

    set(progressRef, { uniqueId: contentId, currentTime: status.currentTime, durationTime: status.duration | 0 })

    const LibraryRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/`);
    const libSnapshot = await get(LibraryRef);

    return libSnapshot.val()
}




function getProfile() {
    const profile = window.sessionStorage.getItem("profile");
    return Number(profile);
}


function VideoPlayer({ setMiniPlayer, music }: any) {
    const { contentId } = useParams();
    const { user, profile, library, setLibrary } = useAuth();
    const [content, setContent] = useState<any>(null);

    useEffect(() => {
        if (music && music.videoElem) {
            music.videoElem.pause()
        }

        setMiniPlayer(false);
        if (user) {
            getContents();
        }
    }, [user]);

    const getContents = async () => {
        const starCountRef = ref(database, "contents/");
        const snapshot = await get(starCountRef);
        const contents = snapshot.val();

        const con = Object.values(contents).find(
            (item: any) => item.uniqueId == contentId
        );

        if (con) {
            setContent(con);
        }

        // Content not found, handle accordingly (e.g., redirect to 404 page)
    };


    // Render video player UI
    return (
        <>
            {/* {isMobile && !isTablet ? (
                <Navigate to="/mobile" replace />
            ) : ( */}
            <div className="video-player">
                {/* Render video player controls and playback */}
                {content && (
                    <VideoControl
                        tracks={content.captions}
                        content={content}
                        src={content.videoUrl}
                        title={content.title}
                        startingTime={(user?.profiles[getProfile() || 0]?.library?.progress[String(contentId)]?.currentTime) || 0}
                        getStatus={(status: any) => {
                            addToProgress(user?.uid, Object.values(user?.profiles || []).findIndex((items) => items.username === profile.username) || 0, content?.uniqueId, status).then((data: any) => {
                                setLibrary(data)
                              })
                        }}
                    />
                )}
                {!user && <Navigate to="/login" replace />}
            </div>
            {/* )} */}
        </>
    )
}

export default VideoPlayer;
