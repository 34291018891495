import React from "react";
import { Link } from "react-router-dom";

interface Styles {
    [key: string]: React.CSSProperties;
  }

interface EpisodeProps {
  thumbnail: string;
  title: string;
  episodeNumber: number;
  overview: string;
  watchId:string;
  setFirstEpisode: any
}

const EpisodeListItem: React.FC<EpisodeProps> = ({
  thumbnail,
  title,
  episodeNumber,
  overview,
  watchId,
  setFirstEpisode,
}) => {

  if(episodeNumber == 1){
    setFirstEpisode(watchId)
  }
  const styles: Styles = {
    episodeItem: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#fff",
      borderBottom: "1px solid #ffffff20",
      padding: "20px",
    },
    thumbnail: {
      width: "150px",
      aspectRatio: "16/9",
      marginRight: "20px",
      borderRadius: "10px",
    },
    title: {
      color: "white",
    },
    overview: {
      color: "#ffffff90",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
    },
    // Add more styles as needed
  };

  return (
    <Link
      className="item"
      to={`/watch/e/${watchId}`}
      style={styles.episodeItem}
    >
      <img
        src={thumbnail}
        alt={`Thumbnail for ${title}`}
        style={styles.thumbnail}
      />
      <div>
        <b style={styles.title}>{title}</b>
        <p>Episode {episodeNumber}</p>
        <p style={styles.overview}>{overview}</p>
      </div>
    </Link>
  );
};

export default EpisodeListItem;
