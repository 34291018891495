import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import Background from "../components/Background";
import Logo from "../components/Logo";
import { useAuth } from "../context/AuthContext";
import { ref, onValue, set, push, get } from "@firebase/database";
import { database, auth } from "../firebase";
import { Navigate } from "react-router-dom";
import Message, { MessageInterface } from "../components/message";

interface Styles {
    [key: string]: React.CSSProperties;
}

function createUser(uid: any, username: any, email: any){
  const userRef = ref(database, "users/" + uid);
  set(userRef, {email: email, uid: uid, subscription: 'inactive'})
  createProfile(uid, username)
}

function createProfile(uid:any, username:any){
  const userRef = ref(database, "users/" + uid +"/profiles");
  get(userRef).then((value)=>{
    const data = value.val()
    if(data){
      const userRef = ref(database, "users/" + uid +"/profiles/" + Object.values(data).length);
      set(userRef, {username: username, profileUrl: "https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar_3d-300x300.jpg"})
    }else{
      const userRef = ref(database, "users/" + uid +"/profiles/" + 0);
      set(userRef, {username: username, profileUrl: "https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar_3d-300x300.jpg"})
    }
  })
  // push(userRef, {username: username, profileUrl: "https://www.kaericature.com/wp-content/uploads/2023/05/custom_avatar_3d-300x300.jpg"})
}


export default function Signup() {
  const { signup, isAuthenticated, user } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [loginMessage, setLoginMessage] = useState<MessageInterface | null>(
    null
  );

  function handleSignup(e: any) {
    e.preventDefault();
    if (password == verifyPassword) {
      signup(email, password).then(
        (data) => {
          createUser(data.user.uid, username, data.user.email)
          setLoginMessage({
            type: "succeed",
            title: "Great",
            body: "Sign Up Succeed",
          });
        },
        (error) => {
          setLoginMessage({
            type: "error",
            title: "Error",
            body: error.code.replace("auth/", "").replace(/\-/g, " "),
          });
        }
      );
    } else {
      setLoginMessage({
        type: "error",
        title: "Error",
        body: "passwords don't match",
      });
    }

    setTimeout(() => {
      setLoginMessage(null);
    }, 5000);
  }

  return (
    <Background>
      <div style={styles.container}>
        <Logo style={{ marginBottom: "100px" }} />
        <form style={styles.form}>
          <div style={styles.inputGroup}>
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
              style={styles.input}
              value={username}
              onChange={(e) => setUsername(e.currentTarget.value)}
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              style={styles.input}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <div style={styles.inputGroup}>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
              style={styles.input}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              autoComplete="new-password"
            />
            <input
              type="checkbox"
              id="show-password"
              checked={isPasswordVisible}
              onChange={(e) => setPasswordVisibility(e.currentTarget.checked)}
            />
            <label htmlFor="show-password"></label>
          </div>
          <div style={styles.inputGroup}>
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="verify-password"
              placeholder="Verify your password"
              style={styles.input}
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.currentTarget.value)}
              autoComplete="new-password"
            />
          </div>
          <button type="submit" style={styles.button} onClick={handleSignup}>
            Sign Up
          </button>
          <Link to="/login" style={styles.links}>
            I already have an account
          </Link>
        </form>
      </div>
      {loginMessage && (
        <Message
          type={loginMessage.type}
          title={loginMessage.title}
          body={loginMessage.body}
        />
      )}
      {user && <Navigate to="/" replace />}
    </Background>
  );
}

const styles: Styles = {
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  inputGroup: {
    marginBottom: "15px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },
  input: {
    padding: "8px 15px",
    fontSize: "14px",
    flex: "1",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#00000050",
    color: "white",
    outline: "none !important",
  },
  button: {
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "100%",
  },
  links: {
    margin: "10px",
    fontSize: "12px",
    color: "#ffffff90",
    textDecoration: "none",
  },
};
