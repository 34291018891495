import React, { useState, useEffect, useContext, useRef } from "react";
import { database } from "../firebase";
import { ref, get, set, push, remove } from "@firebase/database";
import { useAuth } from "../context/AuthContext";
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHeartCrack,
    faAdd,
    faCheck,
    faPlay,
    faPause,
    faExpand,
    faRotateLeft,
    faForwardStep,
    faClosedCaptioning,
    faRotateRight,
    faVolumeHigh,
    faVolumeLow,
    faVolumeOff,
    faVolumeXmark,
    faChevronLeft,
    faCompress,
    faGear,
    faHeart
} from "@fortawesome/free-solid-svg-icons";

import Caption from "./Caption";


import SettingHome from "./Settings"


async function addToLibrary(uid: string | undefined | null, profileIndex: number, contentId: string) {
    const LibraryAddedRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/added/`);

    // Check if the contentId already exists under any key
    const snapshot = await get(LibraryAddedRef);

    if (snapshot.exists()) {
        let exists = false;

        // Loop through the snapshot to find if contentId exists under any key
        snapshot.forEach((childSnapshot: any) => {
            if (childSnapshot.val() === contentId) {
                exists = true;
                // If contentId exists, remove it
                remove(childSnapshot.ref);
            }
        });

        // If contentId doesn't exist, add it
        if (!exists) {
            const newPostRef = push(LibraryAddedRef);
            set(newPostRef, contentId);
        }
    } else {
        // If the library is empty, add the contentId
        const newPostRef = push(LibraryAddedRef);
        set(newPostRef, contentId);
    }

    const LibraryRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/`);
    const libSnapshot = await get(LibraryRef);

    return libSnapshot.val()
}


async function addToDislike(uid: string | undefined | null, profileIndex: number, contentId: string) {
    const LibraryAddedRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/disliked/`);

    // Check if the contentId already exists under any key
    const snapshot = await get(LibraryAddedRef);

    if (snapshot.exists()) {
        let exists = false;

        // Loop through the snapshot to find if contentId exists under any key
        snapshot.forEach((childSnapshot: any) => {
            if (childSnapshot.val() === contentId) {
                exists = true;
                // If contentId exists, remove it
                remove(childSnapshot.ref);
            }
        });

        // If contentId doesn't exist, add it
        if (!exists) {
            const newPostRef = push(LibraryAddedRef);
            set(newPostRef, contentId);
        }
    } else {
        // If the library is empty, add the contentId
        const newPostRef = push(LibraryAddedRef);
        set(newPostRef, contentId);
    }

    const LibraryRef = ref(database, `users/${uid}/profiles/${profileIndex}/library/`);
    const libSnapshot = await get(LibraryRef);

    return libSnapshot.val()
}


interface Option {
    name: string;
    action: (event: any) => void;
    feedback?: string | number;
}

interface Options {
    [key: string]: Option | null;
}



interface Styles {
    [key: string]: React.CSSProperties;
}

function timeFormatter(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds / 60) % 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedhours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedhours}:${formattedMinutes}:${formattedSeconds}`;
}


function VideoControl({
    content,
    src,
    title,
    overrideOverview,
    overrideSubtitle,
    tracks,
    index,
    episodes,
    startingTime = 0,
    getStatus = (e: any) => { }
}: any) {
    const { user, profile, library, setLibrary } = useAuth();

    const [playNextEpisode, setPlayNextEpisode] = useState(false);
    const navigate = useNavigate();
    const [timer, setTimer] = useState<any>(null);
    const [detailTimer, setDetailTimer] = useState<any>(null);
    const [captions, setCaptions] = useState<any[]>([]);
    const [isVisible, setSetVisibility] = useState(false);
    const [isDetailVisible, setSetDetailVisibility] = useState(false);
    const [captionIndex, setCaptionIndex] = useState("English")
    const [isShowSettings, setShowSettings] = useState(false)
    const [settings, setSettings] = useState({
        playbackSpeed: "Normal",
        caption: true,
        captionLang: 'en',
        captionSize: 14,
        autoSkipIntro: false
    });
    const [status, setStatus] = useState({
        muted: false,
        volume: 1,
        duration: 0,
        currentTime: startingTime,
        isPlaying: false,
    });

    const [video, setVideo] = useState<any>(null);
    const videoRef = useRef<any>(null);

    useEffect(() => {

        console.log(startingTime)
        // Update the video source when src prop changes
        if (videoRef.current) {
            videoRef.current.src = src;
            videoRef.current.load();
            videoRef.current.currentTime = startingTime
            setStatus({
                muted: false,
                volume: 1,
                duration: 0,
                currentTime: startingTime,
                isPlaying: false,
            });
        }
    }, [src]);

    useEffect(() => {
        // Update the video reference when the video element is loaded
        if (videoRef.current) {
            setVideo(videoRef.current);
        }
    }, [videoRef.current]);

    const [captionSize, setCaptionSize] = useState<number>(14)

    const styles: Styles = {
        videoControl: { height: "100vh", width: "100vw" },
        seekBarContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginTop: "auto",
            width: "100%",
        },

        seekBar: {
            backgroundColor: "#ffffff50",
            width: "calc(100% - 80px)",
            height: "5px",
            borderRadius: "10px",
            overflow: "hidden",
            transition: ".2s",
        },

        seek: {
            height: "100%",
            width: `${(status.currentTime / status.duration) * 100}%`,
            backgroundColor: "#ffffff",
        },

        volume: {
            height: "100%",
            width: `${(status.muted ? 0 : status.volume / 1) * 100}%`,
            backgroundColor: "#ffffff",
        },
        controls: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
        },

        overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: "50px 100px",
            opacity: video?.paused ? 1 : isVisible ? 1 : 0,
            cursor: video?.paused ? "default" : isVisible ? "default" : "none",
            backgroundColor: "#00000050",
            transition: ".2s",
            // justifyContent: "stretch",
        },

        controlbuttonsContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "200px",
        },

        controlbuttons: {
            height: "40px",
            minWidth: "40px",
            borderRadius: 100,
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            background: "transparent",
            gap: 10,
            outline: "none",
        },
        back: {
            position: "absolute",
            top: 0,
            left: "50px",
            backgroundColor: "transparent",
            border: "none",
            padding: "50px 50px",
            cursor: "pointer",
            opacity: video?.paused ? 1 : isVisible ? 1 : 0,
        },

        backdrop: {
            width: "100%",
            opacity: isDetailVisible ? 1 : 0,
            transition: "1s",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
        },
        faded: {
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        logo: {
            width: "25%",
            aspectRatio: 3 / 1,
            margin: "100px 0px 0px 0px",
            objectFit: "contain",
        },
        about: {
            maxWidth: "75%",
            margin: "20px 0px",
        },
        overview: {
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3,
        },
        buttons: {
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            margin: "50px 20px 20px 0px",
        },

        watchButton: {
            gap: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 30px",
            borderRadius: "10px",
            background: "white",
            textDecoration: "none",
            color: "black",
            border: "none",
        },

        addButton: {
            height: "38px",
            width: "38px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            padding: "10px",
            borderRadius: "100px",
            backgroundColor: "white",
            color: "black",
            border: "none",
        },

        caption: {
            marginBottom: video?.paused ? 100 : isVisible ? 100 : 0,
            fontSize: captionSize
        },

        settings: {
            position: "absolute",
            backgroundColor: "#000000dd",
            color: "white",
            padding: "10px 20px",
            borderRadius: 5,
            display: "none",
            border: "1px solid #55555590"
        }
    };
    const [isFullscreen, setFullscreen] = useState(false);

    useEffect(() => {
        setPlayNextEpisode(false);
        function handleKeyPress(event: any) {
            event.preventDefault();
            switch (event.key) {
                case " ": // Spacebar for play/pause
                    playtoggle(event);
                    break;
                case "ArrowLeft": // Left arrow for seeking backward
                    seek(event, status.currentTime - 10, video);
                    break;
                case "ArrowRight": // Right arrow for seeking forward
                    seek(event, status.currentTime + 10, video);
                    break;
                case "ArrowUp": // Right arrow for seeking forward
                    changeVolume(status.volume + 0.1, video);
                    break;
                case "ArrowDown": // Right arrow for seeking forward
                    changeVolume(status.volume - 0.1, video);
                    break;
                case "f": // "f" for fullscreen
                    handleFullscreen(event);
                    break;
                case "c":
                    toggleCaption(event)
                    break;
                case "m": // "m" for muting/unmuting
                    video.muted = !video.muted;
                    break;
                case "Escape": // Escape key to exit fullscreen
                    if (isFullscreen) handleFullscreen(event);
                    break;
                default:
                    break;
            }
        }

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [src, status, video, isFullscreen, playNextEpisode]);


    function updateStatus(e: any) {
        const s = e.currentTarget;
        const newStatus = {
            muted: s.muted,
            volume: s.volume,
            duration: s.duration,
            currentTime: s.currentTime,
            isPlaying: !s.paused,
        };
        setStatus(newStatus);
        getStatus(newStatus)
    }

    function seek(e: any, time: number, video: any) {
        e.stopPropagation()
        video.currentTime = time;
    }

    function changeVolume(volume: number, video: any) {
        if (volume <= 1 && volume >= 0) {
            status.volume = volume;
            video.volume = volume;
        }
    }


    function playtoggle(e: any) {
        e.stopPropagation()
        if (status.isPlaying) {
            video?.pause();
            setStatus({ ...status, isPlaying: false });
            const y = setTimeout(() => {
                setSetDetailVisibility(true);
            }, 5000);

            setDetailTimer(y);
        } else {
            video?.play();
            clearTimeout(detailTimer);
            setSetDetailVisibility(false);
            setStatus({ ...status, isPlaying: true });
        }
    }

    // let timer;

    function handleFullscreen(e: any) {
        e.stopPropagation()
        const elem = document.querySelector("div.video_player");

        if (!document.fullscreenElement) {
            elem?.requestFullscreen();
            setFullscreen(true);
        } else {
            document.exitFullscreen();
            setFullscreen(false);
        }
    }

    const fetchCaption = (input: any) => {
        return fetch(input)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw new Error('Failed to fetch caption: ' + response.status);
                }
            })
            .catch((error) => {
                console.error('Error fetching caption:', error);
                throw error; // rethrow the error to propagate it to the caller
            });
    };

    useEffect(() => {
        if (tracks && tracks[0]) {
            const et: any[] = []
            tracks.forEach((track: any, index: number) => {
                fetchCaption(track.src).then((e) => {
                    const r = {
                        label: track.label,
                        text: e
                    }
                    et.push(r)
                })
            })
            setCaptions(et)
        }

    }, [])







    function toggleCaption(e: any) {
        e.stopPropagation()

        if (settings.caption) {
            setSettings({ ...settings, caption: false })
        } else {
            setSettings({ ...settings, caption: true })
        }
    }

    function handleSpeedChange(speed: number, name: string,) {
        if (videoRef.current) {
            videoRef.current.playbackRate = speed;
            setSettings({ ...settings, playbackSpeed: name })
        }
    };



    const [settinScreen, setSettingScreen] = useState(<></>)

    //setCaptionSize

    useEffect(() => {

        const playbackOptions: Option[] = [
            {
                name: "0.25x",
                action: (e) => {
                    handleSpeedChange(0.25, "Slower")
                },
                feedback: "Slower"
            },
            {
                name: "0.5x",
                action: (e) => {
                    handleSpeedChange(0.5, "Slow")
                },
                feedback: "Slow"
            },
            {
                name: "1x",
                action: (e) => {
                    handleSpeedChange(1, "Normal")
                },
                feedback: "Normal"
            },
            {
                name: "1.5x",
                action: (e) => {
                    handleSpeedChange(1.5, "Fast")
                },
                feedback: "Fast"
            },
            {
                name: "2x",
                action: (e) => {
                    handleSpeedChange(2, "Faster")
                },
                feedback: "Faster"
            },
            {
                name: "5x",
                action: (e) => {
                    handleSpeedChange(5, "xFast")
                },
                feedback: "xFast"
            },
        ]

        const captionSizeOptions: Option[] = [
            {
                name: "Extra Small",
                action: (e) => {
                    setCaptionSize(8)
                },
                feedback: 8
            },
            {
                name: "Small",
                action: (e) => {
                    setCaptionSize(14)
                },
                feedback: 14
            },
            {
                name: "Medium",
                action: (e) => {
                    setCaptionSize(18)
                },
                feedback: 18
            },
            {
                name: "Large",
                action: (e) => {
                    setCaptionSize(24)
                },
                feedback: 24
            },
            {
                name: "Extra Large",
                action: (e) => {
                    setCaptionSize(32)
                },
                feedback: 32
            },

        ]


        const settingOptions: Option[] = [

            {
                name: "Playback Speed",

                action: (e) => {
                    setSettingScreen(
                        <ChildSetting name="Playback Speed"
                            options={playbackOptions}
                            selectedOption={playbackOptions.findIndex(items => items.feedback === settings.playbackSpeed)}
                            onClickBack={() => {
                                setSettingScreen(<SettingHome name="Settings" options={settingOptions} />)
                            }} />
                    )
                },

                feedback: playbackOptions[playbackOptions.findIndex(items => items.feedback === settings.playbackSpeed)].feedback
            },]
        if (captions) {

            settingOptions.push({
                name: "Caption?",
                action: toggleCaption,
                feedback: settings.caption ? "on" : "off"
            }, {
                name: "Caption Languages",
                feedback: captionIndex,
                action: (e) => {
                    setSettingScreen(<ChildSetting name="Caption Languages"
                        selectedOption={captions.findIndex(items => items.label === captionIndex)}
                        options={captions.map((track: any, index: number) => {
                            const rt: Option = {
                                name: track.label,
                                action: (e) => { setCaptionIndex(track.label) }
                            }

                            return rt
                        })} onClickBack={() => { setSettingScreen(<SettingHome name="Settings" options={settingOptions} />) }} />)
                }
            }, {
                name: "Caption Size",
                action: (e) => {
                    setSettingScreen(<ChildSetting name="Caption Size"
                        selectedOption={captionSizeOptions.findIndex(items => items.feedback === captionSize)}
                        options={captionSizeOptions}
                        onClickBack={() => { setSettingScreen(<SettingHome name="Settings" options={settingOptions} />) }} />)
                },
                feedback: captionSizeOptions[captionSizeOptions.findIndex(items => items.feedback === captionSize)].name
            })


        }






        setSettingScreen(<SettingHome name="Settings" options={settingOptions} />)

    }, [settings, captions, captionIndex, captionSize])






    return (
        <div
            className="video_player"
            style={styles.videoControl}
            onMouseMove={() => {
                clearTimeout(timer);
                setSetVisibility(true);
                const y = setTimeout(() => {
                    setSetVisibility(false);
                    setShowSettings(false)
                }, 5000);

                setTimer(y);
            }}
        >
            <video
                ref={videoRef}
                autoPlay
                onLoadedData={(e) => {
                    updateStatus(e);
                    // setVideo(e.currentTarget);
                }}
                onTimeUpdate={updateStatus}
                style={{ width: "100%", height: "100%" }}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {settings.caption && captions[0] && (
                <Caption style={styles.caption} text={captions[captions.findIndex(items => items.label === captionIndex)]?.text || ""} videoTime={status.currentTime} />
            )}
            <div style={styles.overlay} onClick={(e) => {
                if (isShowSettings) {
                    setShowSettings(false)
                } else {
                    playtoggle(e);
                }
            }}>
                <div style={styles.backdrop}>
                    <div style={styles.faded}>
                        <img style={styles.logo} src={content?.logo} />
                        <div style={styles.about}>
                            <b>
                                {overrideSubtitle ? (
                                    overrideSubtitle
                                ) : (
                                    <>
                                        <span>{`${content?.rating}`}</span>
                                        {` ${new Date(content?.released).getFullYear()} ‧ ${content?.tag
                                            } ‧ ${content?.length}`}
                                    </>
                                )}
                            </b>
                            <p style={styles.overview}>
                                {overrideOverview || content?.overview}
                            </p>
                        </div>
                        <div style={styles.buttons}>


                            {user && library && (
                                <button style={styles.addButton} onClick={(e) => {
                                    e.stopPropagation()
                                    addToDislike(user.uid, Object.values(user?.profiles || []).findIndex((items) => items.username == profile.username) || 0, content?.uniqueId).then((data: any) => {
                                        setLibrary(data)
                                    })
                                }}>
                                    <FontAwesomeIcon icon={library?.disliked ? Object.values(library?.disliked)?.includes(content?.uniqueId) ? faHeartCrack : faHeart : faHeart} size="sm" color="black" />
                                </button>
                            )}

                            {user && library && (
                                <button style={styles.addButton} onClick={(e) => {
                                    e.stopPropagation()
                                    addToLibrary(user.uid, Object.values(user?.profiles || []).findIndex((items) => items.username == profile.username) || 0, content?.uniqueId).then((data: any) => {
                                        setLibrary(data)
                                    })
                                }}>
                                    <FontAwesomeIcon icon={library?.added ? Object.values(library?.added)?.includes(content?.uniqueId) ? faCheck : faAdd : faAdd} size="sm" color="black" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div style={styles.seekBarContainer}>
                    <span
                        style={{
                            position: "absolute",
                            display: "none",
                            transform: "translate(-50%, -20px)",
                        }}
                        className="positionIndicator1"
                    >
                        --:--
                    </span>
                    <p>{timeFormatter(status.currentTime)}</p>
                    <div
                        style={styles.seekBar}
                        className="seekbar"
                        onClick={(e) => {
                            const x = e.clientX - e.currentTarget.offsetLeft;
                            const width = e.currentTarget.clientWidth;
                            const position = (x / width) * status.duration;
                            seek(e, position, video);
                        }}
                        onMouseEnter={(e) => {
                            const element: any = document.querySelector("span.positionIndicator1");
                            element.style.display = "block";
                            // element.style.top = `${-20}px`;
                            element.style.left = `${e.clientX}px`;
                        }}
                        onMouseMove={(e) => {
                            const element: any = document.querySelector("span.positionIndicator1");
                            // element.style.top = `${-20}px`;
                            element.style.left = `${e.clientX}px`;

                            const x = e.clientX - e.currentTarget.offsetLeft;
                            const width = e.currentTarget.clientWidth;
                            const position = (x / width) * status.duration;

                            element.innerHTML = timeFormatter(position);
                        }}
                        onMouseLeave={(e) => {
                            const element: any = document.querySelector("span.positionIndicator1");
                            element.style.display = "none";
                            // element.style.top = `${-20}px`;
                            element.style.left = `${e.clientX}px`;
                        }}
                    >
                        <div style={styles.seek}></div>
                    </div>
                    <p>{timeFormatter(status.duration)}</p>
                </div>
                <div style={styles.controls}>
                    <div style={{ flex: 1 }}>
                        <b>{title}</b>
                    </div>
                    <div>
                        <div style={styles.controlbuttonsContainer}>
                            <button
                                style={styles.controlbuttons}
                                onClick={(e) => {
                                    seek(e, status.currentTime - 10, video);
                                }}
                            >
                                {/* <i className="fas fa-backward-step"></i> */}
                                <FontAwesomeIcon icon={faRotateLeft} size="lg" color="white" />
                            </button>
                            <button style={styles.controlbuttons} onClick={playtoggle}>
                                <FontAwesomeIcon
                                    icon={status.isPlaying ? faPause : faPlay}
                                    size="xl"
                                    color="white"
                                />
                            </button>
                            <button
                                style={styles.controlbuttons}
                                onClick={(e) => {
                                    seek(e, status.currentTime + 10, video);
                                }}
                            >
                                <FontAwesomeIcon icon={faRotateRight} size="lg" color="white" />
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            ...styles.controlbuttonsContainer,
                            flex: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        <button style={styles.controlbuttons}>
                            <FontAwesomeIcon
                                icon={
                                    status?.muted
                                        ? faVolumeXmark
                                        : status?.volume > 0
                                            ? status?.volume > 0.1
                                                ? status?.volume > 0.5
                                                    ? faVolumeHigh
                                                    : faVolumeLow
                                                : faVolumeOff
                                            : faVolumeXmark
                                }
                                size="sm"
                                color="white"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    video.muted = video?.muted ? false : true;
                                }}
                            />

                            <div
                                style={{ ...styles.seekBar, minWidth: "50px" }}
                                className="volume"
                                onClick={(e) => {
                                    const x = e.clientX - e.currentTarget.offsetLeft;
                                    const width = e.currentTarget.clientWidth;
                                    const position = Number((x / width).toFixed(1));
                                    changeVolume(position, video);
                                }}
                            >
                                <div style={styles.volume}></div>
                            </div>
                        </button>
                        {tracks && captions[0] && (
                            <>
                                <button style={{ ...styles.controlbuttons, borderBottom: "2px solid transparent", borderRadius: 0, borderColor: settings.caption ? "#0090ff" : "transparent" }} onClick={toggleCaption}>
                                    <FontAwesomeIcon
                                        icon={faClosedCaptioning}
                                        size="sm"
                                        color={"white"}
                                    />
                                </button>


                            </>
                        )}

                        <button onClick={(e) => { e.stopPropagation(); setShowSettings(!isShowSettings) }} style={{ ...styles.controlbuttons, borderBottom: "2px solid transparent", borderRadius: 0, borderColor: isShowSettings ? "#ffffff" : "transparent" }}>
                            <FontAwesomeIcon
                                icon={faGear}
                                size="sm"
                                color="white"
                            />
                        </button>

                        <button onClick={handleFullscreen} style={styles.controlbuttons}>
                            <FontAwesomeIcon
                                icon={isFullscreen ? faCompress : faExpand}
                                size="sm"
                                color="white"
                            />
                        </button>
                        {index != null && episodes[index + 1] && (
                            <button
                                style={{
                                    ...styles.controlbuttons,
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    aspectRatio: "unset",
                                    gap: "10px",
                                    padding: "0px 10px",
                                }}
                                onClick={() => {
                                    setPlayNextEpisode(true);
                                }}
                            >
                                <b>Play Next Episode</b>
                                <FontAwesomeIcon icon={faForwardStep} size="sm" color="black" />
                            </button>
                        )}
                        {playNextEpisode && episodes[index + 1] && (
                            <Navigate
                                to={`/watch/e/${episodes[index + 1].publicId}`}
                                replace
                            />
                        )}
                        {isShowSettings && (<div className="settings" style={styles.settings}>
                            <div onClick={(e) => { e.stopPropagation() }} style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                                {settinScreen}
                                {/* {captions.map((track: any, index: number) => (
                                    <button onClick={(e) => { e.stopPropagation(); setCaptionIndex(index) }} style={{ textAlign: "left", border: "none", backgroundColor: "transparent", color: "white", paddingRight: "40px" }} key={index}>{track.label}</button>
                                ))} */}
                            </div>
                        </div>)}

                    </div>
                </div>
            </div>
            <button
                style={styles.back}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} size="sm" color="white" />
            </button>
        </div>
    );
}


const home: Option[] = [
    {
        name: "Nothing to see here",
        action: (e) => {
            // Add functionality here
        },
        feedback: ""
    }
]


function ChildSetting({ name = "New Page", options = home, selectedOption, onClickBack }: { name?: string, options?: Option[], selectedOption?: number, onClickBack: (e: any) => void }) {
    return (
        <div>
            <div style={{ display: "flex", gap: 5, alignItems: "center" }} >
                <FontAwesomeIcon style={{ paddingRight: 15, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); onClickBack(e) }} icon={faChevronLeft} size="2xs" color="white" />
                <p style={{ color: "#ffffff90" }}>{name}</p>
            </div>

            <hr style={{ borderColor: "#ffffff20" }} />
            {Object.values(options).map((option: Option, index: number) => (
                <div style={{ display: "flex", backgroundColor: selectedOption === index ? "#0090ff" : "transparent" }} onClick={(e) => { e.stopPropagation(); option.action(e) }} className="settingOptions" key={index}>
                    {option.name}
                    <span style={{ marginLeft: "auto", color: "#ffffff50" }}>{option.feedback}</span>
                </div>
            ))}
        </div>
    )
}




export default VideoControl;
