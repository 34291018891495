import React, { useState, useEffect } from "react";

interface Styles {
    [key: string]: React.CSSProperties;
}

const formatSecondsToMillis = (totalSeconds: any) => Math.floor(totalSeconds * 1000);

const timeReg = (input: any) => {
    const timeRegex = /(\d{2}):(\d{2}):(\d{2}),(\d{3})/g;
    const matches = [...input.matchAll(timeRegex)];
    const [hours, minutes, seconds, milliseconds] = matches[0].slice(1).map(Number);
    return hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;
};

const Caption = ({ text = "", videoTime, style }: any) => {
    const [currentSub, setCurrentSub] = useState("");

    useEffect(() => {
        const currentTimeMillis = formatSecondsToMillis(videoTime);
        const subtitles = text.replace(/\r/g, '').split(/\n\s*\n/g);

        const currentSubtitle: string = subtitles.find((subtitle: any) => {
            const subtitleMatches = subtitle.match(/(\d{2}:\d{2}:\d{2},\d{3})/g);
            if (subtitleMatches) {
                const [startTime, endTime] = subtitleMatches.map(timeReg);
                return startTime <= currentTimeMillis && currentTimeMillis <= endTime;
            }
            return false;
        });



        setCurrentSub(
            currentSubtitle
                ? String(currentSubtitle)
                    .split(/\d\n/g)[2]
                    .replace(/<i>/g, "") // Remove <i> tags
                    .replace(/<\/i>/g, "") // Remove </i> tags
                    .replace(/{\\an8}/g, "") // Remove {\an8} tags
                    .replace('Downloaded from', "")
                    .replace('YTS.MX', "")
                    .replace('Official YIFY movies site:', "")
                    .trim()
                : ""
        );


    }, [videoTime, text]);

    return (
        <>
            {currentSub ? (

                <div style={{ ...styles.container, ...style }}>
                    {currentSub.split(/\n/).map((text, index) => (
                        <p key={index}>{`${text}`}</p>
                    ))}


                </div>
            ) : (
                <></>
            )}
        </>


    );
};



export default Caption;


const styles: Styles = {
    container: {
        position: "absolute",
        bottom: "50px",
        left: "50vw",
        transform: "translateX(-50%)",
        backgroundColor: "#00000050",
        padding: 5,
        borderRadius: 5
    }
}