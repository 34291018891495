import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faFilm,
    faTv,
    faMusic,
    faIcons,
} from "@fortawesome/free-solid-svg-icons";


interface Styles {
    [key: string]: React.CSSProperties;
  }

const BottomNavBar = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <nav style={styles.nav}>
      <ul style={styles.navList}>
        <li style={styles.navItem}>
          <Link to="/" style={styles.navLink}>
            <FontAwesomeIcon icon={faHome} size="sm" />
            Home
          </Link>
        </li>
        <li style={styles.navItem}>
          <Link to="/movies" style={styles.navLink}>
          <FontAwesomeIcon icon={faFilm} size="sm" />
            Movies
          </Link>
        </li>
        <li style={styles.navItem}>
          <Link to="/tv" style={styles.navLink}>
          <FontAwesomeIcon icon={faTv} size="sm" />
            TV Shows
          </Link>
        </li>
        <li style={styles.navItem}>
          <Link to="/music" style={styles.navLink}>
          <FontAwesomeIcon icon={faMusic} size="sm" />
            Music
          </Link>
        </li>
        {user?.profiles[Number(sessionStorage.getItem('profile'))].library.added && (
                  <li style={styles.navItem}>
                  <Link to="/library" style={styles.navLink}>
                  <FontAwesomeIcon icon={faIcons} size="sm" />
                    Library
                  </Link>
                </li>
        )}

      </ul>
    </nav>
  );
};

const styles: Styles = {
  nav: {
    backgroundColor: "#000000",
    color: "#ffffff",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 1000,
  },
  navList: {
    listStyle: "none",
    display: "flex",
    margin: 0,
    padding: "20px 0",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "10px",
  },
  navItem: {
    padding: "50x",
  },
  navLink: {
    textDecoration: "none",
    color: "#ffffff",
    fontSize: "14px",
    display: "flex",
    flexDirection:'column',
    alignItems: "center",
    justifyContent:'center',
    gap: 5
  },
};

export default BottomNavBar;
