import React, { createContext, useState, useEffect, useContext } from "react";
import { database, auth } from "../firebase";
import { ref, onValue, set } from "@firebase/database";

import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  UserCredential
} from "@firebase/auth";

interface Profile {
  profileUrl: string;
  username: string;
  library: any;
}

interface Subscription {
  status: string;
  startAt: string;
  endsAt: string;
  tier: number;
}

interface User {
  email: string;
  profiles: Profile[];
  subscription: Subscription;
  uid: string|null;
}

function getUser(uid: any, onResult = (user: User) => {}) {
  const starCountRef = ref(database, "users/" + uid);
  onValue(starCountRef, (snapshot) => {
    const user = snapshot.val();
    onResult(user);
  });
}


// Define the shape of the context
interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  profile: any;
  setProfile: any;
  signup: (email: string, password: string) => Promise<UserCredential>;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => void;
  library:any;
  setLibrary: any;
}

  // Function to handle user authentication
  const signup = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    signOut(auth);
  };

const initValue = {
  isAuthenticated: false,
  user: null,
  profile: null,
  setProfile: null,
  signup: signup,
  login: login,
  logout: logout,
  library: [],
  setLibrary: ()=>{}
};


// Create the context
const AuthContext = createContext<AuthContextType>(initValue);

// Create a functional component to provide the context
export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<any>()
  const [library, setLibrary] = useState<any>()



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user)
      getUser(user?.uid, (e) => {
        setUser({...e, uid: user?.uid|| null});
        setIsAuthenticated(true);
      });
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, profile, setProfile, signup, login, logout, library, setLibrary }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useAuth = () => useContext(AuthContext);
