import React from "react";

interface Styles {
    [key: string]: React.CSSProperties;
}

export default function Message({ type = "", title = "Title", body = "Body" }) {
  const styles: Styles = {
    error: {
      fontSize: "14px",
      backgroundColor:
        type == "succeed"
          ? "rgb(8, 127, 8)"
          : type == "error"
          ? "rgb(127, 8, 8)"
          : "rgb(8, 8, 8)",
      borderRadius: "10px",
      border: `2px solid ${
        type == "succeed"
          ? "#00ff00"
          : type == "error"
          ? "red"
          : "rgb(8, 60, 127)"
      }`,
      display: "flex",
      gap: "5px",
      minWidth: "100px",
      padding: "10px",
      position: "fixed",
      top: "0px",
      right: "0px",
      margin: "10px",
      zIndex: "10",
    },
  };

  return (
    <div style={styles.error}>
      <b>{title}:</b>
      <p>{body}</p>
    </div>
  );
}

export interface MessageInterface {
  type: string;
  title: string;
  body: string;
}
