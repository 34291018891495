import React from "react";

interface Option {
    name: string;
    action: (event:any) => void;
    feedback?:string|number;
}

interface Options {
    [key: string]: Option|null;
}

const home: Option[] = [
    {
        name: "Video Speed",
        action: (e) => {
            // Add functionality here
        },
        feedback:""
    }
]

export default function SettingHome({ name="New Page", options=home }: { name?:string,  options?: Option[] }) {
    return (
        <div>
            <p style={{color:"#ffffff90"}}>{name}</p>
            <hr style={{borderColor:"#ffffff20"}}/>
            {Object.values(options).map((option: Option, index: number) => (
                <div style={{display:"flex"}} onClick={(e)=>{e.stopPropagation(); option.action(e)}} className="settingOptions" key={index}>
                    {option.name}
                    <span style={{marginLeft:"auto", color:"#ffffff50"}}>{option.feedback}</span>
                </div>
            ))}
        </div>
    )
}