import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faBackwardStep,
  faForwardStep,
  faRepeat,
  faShuffle,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

interface Styles {
  [key: string]: React.CSSProperties;
}

function timeFormatter(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

function Player({ children, video, status, isMiniPlayer, setMiniPlayer }: any) {
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoElem = video?.videoElem;

  useEffect(() => {
    const handleTimeUpdate = (e: any) => {
      const vid = e.currentTarget;
      status.setStatus({
        ...status.status,
        duration: vid.duration,
        currentTime: vid.currentTime,
        isPlaying: !vid.paused,
        isLoop: vid.loop,
      });
    };

    if (videoElem) {
      videoElem.autoplay = true;
      videoElem.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        videoElem.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [videoElem, status]);

  const styles: Styles = {
    miniPlayer: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: winWidth > 720 ? 0 : "80px",
      height: "60px",
      backgroundColor: "red",
      zIndex: 1,
      marginBottom: "0px",
      display: video && isMiniPlayer ? "flex" : "none",
      backgroundImage: `url(${video?.videoElem.poster})`,
      backgroundSize: "100000%",
      // display: "flex",
      flexDirection: "column",
    },
    container: {
      display: "flex",
      backgroundImage: "linear-gradient(-90deg, #00000050, #00000090)",
      width: "100%",
      height: "calc(100% - 5px)",
    },
    video: {
      height: "80%",
      aspectRatio: 1,
      margin: "auto 5px",
      backgroundColor: "black",
      borderRadius: "10px",
    },
    controlbuttonsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      margin: " auto auto",
      flex: 1,
      width: "200px",
    },
    controlbuttons: {
      height: "40px",
      aspectRatio: 1,
      borderRadius: 100,
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      background: "transparent",
    },
    seekBarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      width: "100%",
    },

    seekBar: {
      backgroundColor: "#ffffff50",
      width: "calc(100% - 10px)",
      height: "5px",
      borderRadius: "10px",
      overflow: "hidden",
      transition: ".2s",
    },

    seek: {
      height: "100%",
      width: `${(status.status.currentTime / status.status.duration) * 100}%`,
      backgroundColor: "#ffffff",
    },
  };

  const seek = (time: number) => {
    if (videoElem) {
      videoElem.currentTime = time;
    }
  };

  const playtoggle = () => {
    if (!videoElem.paused) {
      videoElem.pause();
      status.setStatus({ ...status.status, isPlaying: false });
    } else {
      videoElem.play();
      status.setStatus({ ...status.status, isPlaying: true });
    }
  };

  const setRepeat = () => {
    if (!videoElem.loop) {
      videoElem.loop = true;
      status.setStatus({ ...status.status, isLoop: true });
    } else {
      videoElem.loop = false;
      status.setStatus({ ...status.status, isLoop: false });
    }
  };

  return (
    <>
      {children}
      <div style={styles.miniPlayer}>
        <div style={styles.seekBarContainer}>
          <span
            style={{
              position: "absolute",
              display: "none",
              transform: "translateX(-50%)",
            }}
            className="positionIndicator"
          >
            --:--
          </span>
          {/* <p>{timeFormatter(status.currentTime)}</p> */}
          <div
            style={styles.seekBar}
            className="seekbar"
            onClick={(e) => {
              const x = e.clientX - e.currentTarget.offsetLeft;
              const width = e.currentTarget.clientWidth;
              const position = (x / width) * status.status.duration;
              seek(position);
            }}
            onMouseEnter={(e) => {
              const element: any = document.querySelector("span.positionIndicator");
              element.style.display = "block";
              element.style.top = `${-20}px`;
              element.style.left = `${e.clientX}px`;
            }}
            onMouseMove={(e) => {
              const element: any = document.querySelector("span.positionIndicator");
              element.style.top = `${-20}px`;
              element.style.left = `${e.clientX}px`;

              const x = e.clientX - e.currentTarget.offsetLeft;
              const width = e.currentTarget.clientWidth;
              const position = (x / width) * status.status.duration;

              element.innerHTML = timeFormatter(position);
            }}
            onMouseLeave={(e) => {
              const element: any = document.querySelector("span.positionIndicator");
              element.style.display = "none";
              element.style.top = `${-20}px`;
              element.style.left = `${e.clientX}px`;
            }}
          >
            <div style={styles.seek}></div>
          </div>
          {/* <p>{timeFormatter(status.duration)}</p> */}
        </div>
        <div style={styles.container}>
          <img src={video?.videoElem.poster} style={styles.video} />
          <div style={{ margin: "auto 5px", flex: 1 }}>
            <Link to={status?.status.redirecting}>
              <b style={{
                textDecoration: "none", color: "white", display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 1,
              }}>
                {video?.content.title}
              </b>
            </Link>
            <p style={{
              textDecoration: "none", color: "#ffffff90", display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}>
              {video?.content.artist}
            </p>
          </div>
          <div style={styles.controlbuttonsContainer}>
            {winWidth > 720 && (
              <button
                style={styles.controlbuttons}
                onClick={() => seek(status?.status.duration)}
              >
                <FontAwesomeIcon icon={faShuffle} size="sm" color="white" />
              </button>
            )}

            <button style={styles.controlbuttons} onClick={() => seek(0)}>
              <FontAwesomeIcon icon={faBackwardStep} size="lg" color="white" />
            </button>
            <button style={styles.controlbuttons} onClick={playtoggle}>
              <FontAwesomeIcon
                icon={status?.status.isPlaying ? faPause : faPlay}
                size="xl"
                color="white"
              />
            </button>
            <button
              style={styles.controlbuttons}
              onClick={() => seek(status?.status.duration)}
            >
              <FontAwesomeIcon icon={faForwardStep} size="lg" color="white" />
            </button>

            {winWidth > 720 && (
              <button
                style={{
                  ...styles.controlbuttons,
                  opacity: status.status.isLoop ? 1 : 0.2,
                }}
                onClick={setRepeat}
              >
                <FontAwesomeIcon icon={faRepeat} size="sm" color="white" />
              </button>
            )}
          </div>
          <button
            style={styles.controlbuttons}
            onClick={() => setMiniPlayer(false)}
          >
            <FontAwesomeIcon icon={faChevronDown} size="sm" color="white" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Player;
