import React from "react";


export default function Background({ children}:any) {
  return (
    <div style={styles.container}>
      <div style={styles.gr1}></div>
      <div style={styles.gr2}></div>
      <div style={{ position: "relative", zIndex: "1" }}>{children}</div>
    </div>
  );
}

 interface Styles {
    [key: string]: React.CSSProperties;
  }
  

const styles: Styles = {
  container: {
    backgroundColor: "black",
    position: "absolute",
    height: "100vh",
    width: "100vw",
  },
  gr1: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundImage: "linear-gradient(80deg, #0090ff, transparent, #0090ff)",
    backgroundSize: "1000%",
    backgroundPosition: "center",
    opacity: ".5",
  },
  gr2: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundImage: "linear-gradient(20deg, #0090ff, transparent, #0090ff)",
    backgroundSize: "1000%",
    backgroundPosition: "center",
    opacity: ".4",
  },
};
