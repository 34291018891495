import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import Background from "../components/Background";
import Logo from "../components/Logo";
import { useAuth } from "../context/AuthContext";

import { Navigate } from "react-router-dom";

interface Styles {
  [key: string]: React.CSSProperties;
}

export default function Profiles() {
  const [newProfile, setNewProfile] = useState(false);

  const { logout, isAuthenticated, user, setProfile, profile, setLibrary } = useAuth();

  useEffect(()=>{
    if(!profile)
    setProfile(null)
  },[])

  function logoutHandle(e: any) {
    e.preventDefault();
    logout();
  }

  // function saveProfile(index: number) {
  //   window.sessionStorage.setItem("profile", `${index}`);
  // }

  // function getProfile() {
  //   const profile = window.sessionStorage.getItem("profile");
  //   return Number(profile);
  // }

  return (
    <Background>
      <div style={styles.container}>
        <h2>Who's Watching</h2>

        {user && isAuthenticated ?  (
          <div style={styles.profilesList}>
            {Object.values(user?.profiles).map((profile, index) => (
              <button
                key={index}
                className="item"
                style={styles.profiles}
                onClick={() => {
                  setNewProfile(true)
                  setProfile(profile)
                  setLibrary(profile.library)
                }}
              >
                <img
                  style={{
                    ...styles.avatars,
                    // outline: getProfile() == index ? "2px solid #0090ff" : "",
                  }}
                  src={profile.profileUrl}
                />
                <b>{profile.username}</b>
              </button>
            ))}
          </div>
        ) : (
          <Navigate to={"/"} replace />
        )}

        <button
          type="submit"
          style={styles.button}
          onClick={(e) => logoutHandle(e)}
        >
          Logout
        </button>
      </div>
      {newProfile && <Navigate to="/" replace />}
    </Background>
  );
}

const styles: Styles = {
  container: {
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profilesList: {
    width: "60%",
    maxWidth: "500px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    margin: 50,
  },
  profiles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    border: "none",
    background: "transparent",
    color: "white",
  },
  avatars: {
    height: 70,
    width: 70,
    borderRadius: 10,
    backgroundColor: "#ffffff10",
  },
  button: {
    padding: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "black",
    cursor: "pointer",
    border: "2px solid #ffffff20",
    borderRadius: "10px",
    backgroundColor: "#fff",
    maxWidth: "300px",
    width: "80%",
  },
  links: {
    margin: "10px",
    fontSize: "12px",
    color: "#ffffff90",
    textDecoration: "none",
  },
};
