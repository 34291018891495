import React, { useState, useEffect } from "react";
import './App.css';

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";



// import Loading from './components/Loading';
import Profiles from "./pages/Profile";
import Home from "./pages/Home";
import Movies from "./pages/Movies";
import TV from "./pages/TV";
import Music from "./pages/Music";
import Library from "./pages/Library";
import Select from "./pages/SelectFav";
import Search from "./pages/Search";

import VideoPlayer from "./pages/Watch";
import EpisodePlayer from "./pages/WatchEpisodes";
import Listening from "./pages/Listening";

import Player from "./components/Player";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Pin from "./pages/Pin";
import { Header } from "./components/Header";

function App() {

  // const [Profile, setProfile] = useState<any>()
  const {user, profile, setProfile} = useAuth()



  const [isMiniPlayer, setMiniPlayer] = useState(true);
  const [video, setVideo] = useState(null);
  const [status, setStatus] = useState({
    duration: 0,
    currentTime: 0,
    isPlaying: false,
    isLoop: false,
    redirecting: "",
  });
  return (
    <AuthProvider>
      <BrowserRouter>
        <Player
          video={video}
          status={{ status, setStatus }}
          isMiniPlayer={isMiniPlayer}
          setMiniPlayer={setMiniPlayer}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Profiles/>} />
            <Route path="/pin" element={<Pin user={profile} />} />
            <Route path="/" element={<Home setMiniPlayer={setMiniPlayer} />} />
            <Route path="/movies" element={<Movies setMiniPlayer={setMiniPlayer} />} />
            <Route path="/tv" element={<TV setMiniPlayer={setMiniPlayer} />} />
            <Route path="/music" element={<Music setMiniPlayer={setMiniPlayer} />} />
            <Route path="/library" element={<Library Profile={profile} setMiniPlayer={setMiniPlayer} />} />
            <Route path="/select" element={<Select setMiniPlayer={setMiniPlayer} />} />
            <Route path="/watch/:contentId" element={<VideoPlayer setMiniPlayer={setMiniPlayer} music={video} />} />
            <Route path="/watch/e/:contentId" element={<EpisodePlayer setMiniPlayer={setMiniPlayer} music={video} />} />
            <Route path="/search" element={<Search setMiniPlayer={setMiniPlayer} music={video} />} />
            <Route
              path="/listening/:musicId"
              element={
                <Listening
                  setVideo={setVideo}
                  video={video}
                  status={status}
                  setStatus={setStatus}
                  setMiniPlayer={setMiniPlayer}
                />
              }
            />
          </Routes>
        </Player>
      </BrowserRouter>
    </AuthProvider>

  );
}

export default App;
