import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/plinxlogo.png"; // Import your website logo

export default function Logo({ style }:any) {
  return (
    <Link to="/" style={style}>
      <img src={logo} alt="Plinx" style={{ height: "30px" }} />
    </Link>
  );
}
