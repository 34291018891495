import React, { useState, useEffect, useContext } from "react";
import { database } from "../firebase";
import { ref, get } from "@firebase/database";
import { useAuth } from "../context/AuthContext";
import { isMobile, isTablet } from "react-device-detect";
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";


import VideoControl from "../components/VideoControls";

function EpisodePlayer({ setMiniPlayer, music }: any) {
    const { contentId } = useParams();

    const { user } = useAuth();
    const [content, setContent] = useState<any>(null);
    const [episodes, setEpisodes] = useState<any>(null);
    const [episode, setEpisode] = useState<any>(null);
    const [index, setindex] = useState<number>(0);
    const [seasonIndex, setSeasonIndex] = useState<number>(0);

    useEffect(() => {
        if(music && music.videoElem){
            music.videoElem.pause()
        }
        setMiniPlayer(false);
        if (user) {
            const getContents = async () => {
                const starCountRef = ref(database, "contents/");
                const snapshot = await get(starCountRef);
                const contents = snapshot.val();

                const con = Object.values(contents).filter(
                    (item: any) => item.content_type == "TV Shows" && item.seasons
                    // item.status == "onAir"
                );
                const seasons = con.flatMap((item: any) => item.seasons);
                const episodes = seasons.flatMap((season) =>
                    Object.values(season.episodes)
                );
                const episode: any = episodes.find((epi: any) => epi.publicId === contentId);

                if (episode) {
                    const season = seasons.find(
                        (item: any) => item.seasonId == episode.seasonId
                    );
                    const content = con.find((item: any) => item.uniqueId == season.contentId);
                    const e = episodes.filter(
                        (item: any) => item.seasonId == episode.seasonId
                    );

                    const s = seasons.filter((item) => item.seasonId == episode.seasonId);
                    setEpisode(episode);
                    setEpisodes(e);
                    setindex(e.findIndex((item: any) => item.publicId === contentId));
                    setContent(content);
                    setSeasonIndex(
                        s.findIndex((item) => item.seasonId === episode.seasonId)
                    );
                }

                // Content not found, handle accordingly (e.g., redirect to 404 page)
            };

            getContents();
        }
    }, [user, contentId]);

    // Render video player UI
    return (
        <>
            {/* {isMobile && !isTablet ? (
                <Navigate to="/mobile" replace />
            ) : ( */}
                <div className="video-player">
                    {/* Render video player controls and playback */}
                    {episode && (
                        <VideoControl
                            content={content}
                            src={episode.videoUrl}
                            index={index}
                            episodes={episodes}
                            title={episode.title}
                            overrideOverview={episode.overview}
                            overrideSubtitle={`Season ${seasonIndex + 1} ‧ Episode ${index + 1
                                }`}
                        />
                    )}
                    {!user && <Navigate to="/login" replace />}
                </div>
            {/* )} */}
        </>
    );
}

// Define VideoControl component

export default EpisodePlayer;
