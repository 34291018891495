import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { MusicSections } from "../components/Sections";
import { ref, onValue } from "@firebase/database";
import { database } from "../firebase";

interface Styles {
    [key: string]: React.CSSProperties;
  }

function getContent(id: string, onResult = (contents: any) => {}) {
  const starCountRef = ref(database, "contents/");
  onValue(starCountRef, (snapshot: any) => {
    const contents: any = snapshot.val();
    onResult(Object.values(contents).find((item:any) => item.uniqueId == id));
  });
}

function MusicModal({ musicId }: any) {
  const [winWidth, setWindowWidth] = useState(window.innerWidth);
  // This useEffect hook will handle window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [content, setContent] = useState<any>();

  useEffect(() => {
    getContent(musicId, (music: any) => {
      setContent(music);
    });
  }, [musicId]);

  const styles: Styles = {
    container: {
      height: "100%",
      backgroundImage: "linear-gradient(0deg, #00000050, #00000090)",
      display: winWidth > 720 ? "flex" : "",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      overflowY: winWidth > 720 ? "hidden" : "scroll",
    },
    album: {
      flex: 1,
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: winWidth > 720 ? "0" : "100px",
    },

    artwork: {
      width: "200px",
      aspectRatio: 1,
      marginBottom: "20px",
      borderRadius: "10px",
    },
  };

  return (
    <Modal backgroundTheme={`url(${content?.thumbnail})`} name={content?.album}>
      <div style={styles.container}>
        <div style={styles.album}>
          <img style={styles.artwork} src={content?.thumbnail} />
          <h3>{content?.title}</h3>
          <p>{content?.artist}</p>
        </div>
        <div
          style={{
            height: "80%",
            maxHeight: "80%",
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <MusicSections
            column={0}
            name="Songs"
            array={[content]}
            overridepath={`/listening/${content?.uniqueId}`}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MusicModal;
